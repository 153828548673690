import { Form } from "@/shared/globals/forms/interfaces/Form.interface";

export const forgotPasswordStep2 = (action, validations): Form => {
  return {
    sections: [
      {
        fields: [
          {
            path: "email",
            inputName: "emailForgot",
            label: {
              value: "login.email",
              needsTranslate: true,
            },
            type: "email",
            methodsYup: () => {
              const { emailValidation } = validations;
              return emailValidation;
            },
          },
          {
            path: "newPassword",
            inputName: "forgotPassword",
            label: {
              value: "login.password",
              needsTranslate: true,
            },
            type: "password",
            methodsYup: () => {
              const { passwordValidation } = validations;
              return passwordValidation;
            },
          },
          {
            path: "confirmationCode",
            inputName: "confirmationCode",
            label: {
              value: "login.confirmationCode",
              needsTranslate: true,
            },
            type: "text",
            methodsYup: () => {
              const { cofirmationCodeValidation } = validations;
              return cofirmationCodeValidation;
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: { value: "login.submit", needsTranslate: true },
      action,
    },
  };
};
