<template>
  <div class="container-fluid tableParentContainer">
    <div class="row justify-content-between align-items-center mt-3">
      <div class="col-auto d-flex align-items-center">
        <div class="dropdown">
          <span>Results: </span>
          <button
            class="results-button"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ pageSize }} <i class="bi bi-caret-down"></i>
          </button>
          <ul class="dropdown-menu">
            <li
              v-for="option in resultsPerPageOptions"
              :key="`page-option-${option}`"
              @click="pageSize = option"
            >
              <a class="dropdown-item">{{ option }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col tableContainer">
        <table class="w-100 table table-bordered table-hover styled-table">
          <colgroup>
            <col
              v-for="header in translatedHeaders"
              :key="`col-${props.name}-${header.key}`"
              :style="{ width: header.width }"
            />
          </colgroup>
          <thead class="thead-light">
            <tr>
              <th
                class="tableHeader text-center"
                v-for="header in translatedHeaders"
                :key="`header-${props.name}-${header.key}`"
                @click="changeSort(header.key)"
                :class="{ sortable: header.sortable }"
              >
                {{
                  header?.metadata?.customFieldMetadata?.language?.name?.[
                    lang
                  ] ?? header.value
                }}
                <span v-if="orderBy === header?.key">
                  <i v-if="orderType === 'asc'" class="bi bi-arrow-up"></i>
                  <i v-if="orderType === 'desc'" class="bi bi-arrow-down"></i>
                </span>
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th
                class="tableFilter text-center"
                v-for="header in headersRef"
                :key="`header-filter-${props.name}-${header.key}`"
              >
                <ColumnFilter
                  :columnHeader="header"
                  @confirmValue="applyFilters"
                />
              </th>
            </tr>
          </thead>
          <div
            v-if="loadingTable || mappedData.length === 0"
            style="display: table-caption"
            class="container-fluid"
          >
            <div class="row justify-content-center">
              <div class="col-auto mt-3">
                <IsLoading v-if="loadingTable" />
                <h5 v-else>{{ $t("global.tables.emptyData") }}</h5>
              </div>
            </div>
          </div>
          <tbody v-else>
            <tr
              v-for="(row, index) in mappedData"
              :key="`row-${props.name}-${index}`"
              class="tableRow"
            >
              <td
                v-for="(data, dataIndex) in getTableRow(row)"
                :key="`cell-${props.name}-${index}-${dataIndex}`"
                class="text-center"
              >
                <p v-if="data.type === 'text'" class="cell-text">
                  {{ data.value }}
                </p>
                <p v-else-if="data.type === 'date'" class="cell-text">
                  {{ data.value }}
                  <!--
                  {{
                    formatDate(data.value as Date) !== "Invalid Date"
                      ? formatDate(data.value as Date)
                      : "-"
                  }}
                  -->
                </p>
                <p
                  v-else-if="data.type === 'multiple-select'"
                  class="cell-text"
                >
                  {{ data.value }}
                </p>
                <div
                  v-else-if="
                    data.type === 'permission' && Array.isArray(data.value)
                  "
                >
                  <span
                    v-for="(permission, index) in (data.value as Array<string>)"
                    v-bind:key="`${permission}.${index}`"
                  >
                    {{
                      `${$t(`global.permissions.${permission}`)}${
                        index < data.value.length - 1 ? ", " : ""
                      }`
                    }}
                  </span>
                </div>
                <div v-else-if="data.type === 'catalog'">
                  <StatusBadge
                    v-if="((data.value) as StatusInterface).bgColor && ((data.value) as StatusInterface).color"
                    :status="data.value as StatusInterface"
                  />
                  <span v-else>{{ data.value }}</span>
                </div>
                <ProfileTag
                  v-else-if="data.type === 'userTag'"
                  :userData="data.value as UserTag"
                  :showEmail="data.metadata?.showEmail"
                />
                <DropdownMenu
                  v-else-if="data.type === 'action'"
                  :menuData="(data.value.options(currentData[index],index) as Menu)"
                  :name="`menu-${index}-${dataIndex}`"
                />
                <p v-else class="cell-text">{{ data.value }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row justify-content-between align-items-center mt-3">
      <div class="col-auto">
        <span class="page-info"
          >Showing page {{ currentPage }} of {{ totalPages }}</span
        >
      </div>
      <div class="col-auto">
        <nav aria-label="pagination">
          <ul class="pagination justify-content-center align-items-center">
            <li class="page-item">
              <button
                class="prev-next-button"
                @click="prevPage()"
                :disabled="currentPage === 1"
              >
                <i class="bi bi-arrow-left"></i>
              </button>
            </li>
            <li class="page-item">
              <span class="page-link active-page">{{ currentPage }}</span>
            </li>
            <li class="page-item">
              <button
                class="prev-next-button"
                @click="nextPage()"
                :disabled="currentPage === totalPages"
              >
                <i class="bi bi-arrow-right"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useLanguageServices } from "@/composables/useLanguageServices";
import { useTablesServices } from "@/composables/useTablesServices";
import { StatusInterface } from "@/shared/globals/helpers/Catalogs.helper";
//import { formatDate } from "@/shared/globals/helpers/Date.helper";
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { ResultsPerPageOptions } from "@/shared/globals/tables/consts/ResultsPerPage.const";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { UserTag } from "@/shared/globals/tags/types/UserTag.type";
import {
  computed,
  defineExpose,
  defineProps,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import DropdownMenu from "../UiTools/DropdownMenu.vue";
import IsLoading from "../UiTools/IsLoading.vue";
import StatusBadge from "../UiTools/StatusBadge.vue";
import ProfileTag from "../profile/ProfileTag.vue";
import ColumnFilter from "./ColumnFilter.vue";

const { lang } = useLanguageServices();

const props = defineProps({
  headers: {
    type: Object as PropType<TableHeader[]>,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  dataSource: {
    type: String,
    required: true,
  },
  orderBy: {
    type: String,
    default: "id",
  },
  orderType: {
    type: String as PropType<"asc" | "desc">,
    default: "desc",
  },
});

const headersRef = computed(() => props.headers);
const orderBy = ref(props.orderBy);
const orderType = ref(props.orderType);
const {
  getTableData,
  currentPage,
  totalPages,
  pageSize,
  mappedData,
  translatedHeaders,
  loadingTable,
  filters,
  currentData,
  applyFilters,
} = useTablesServices(props.dataSource, headersRef, orderBy, orderType);
const isLoading = computed(() => loadingTable.value);

const resultsPerPageOptions = ResultsPerPageOptions;

const pagesList = computed(() => {
  return Array.from({ length: totalPages.value }, (_, i) => i + 1);
});

function changeSort(columnKey: string) {
  if (orderBy.value === columnKey) {
    orderType.value = orderType.value === "asc" ? "desc" : "asc";
  } else {
    orderBy.value = columnKey;
    orderType.value = "asc";
  }
}

function nextPage() {
  if (currentPage.value < totalPages.value) currentPage.value++;
}

function prevPage() {
  if (currentPage.value > 1) currentPage.value--;
}

function getTableRow(row: { [key: string]: unknown }) {
  return translatedHeaders.value.map((header) => {
    const cellData = row[header.key];

    if (header.columnType === "action") {
      return {
        value: { options: header.options },
        type: "action",
        metadata: header.metadata,
      };
    }

    return {
      value: cellData,
      type: header.columnType || "text",
      metadata: header.metadata,
    };
  });
}

watch(
  [orderBy, orderType, currentPage, pageSize, filters],
  () => {
    getTableData();
  },
  { flush: "post" }
);

onMounted(() => {
  getTableData();
});

defineExpose({ currentData });
</script>

<style lang="scss" scoped>
$table-background-color: white;
$table-header-bg-color: #f1f5ff;
$table-header-text-color: #6c757d;
$table-border-color: #e1e1e1;
$table-row-hover-bg-color: #f9f9f9;
$pagination-active-bg: #007bff;
$pagination-hover-bg: #0056b3;
$pagination-border-color: #dee2e6;

.tableParentContainer {
  background-color: $table-background-color;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.tableContainer {
  margin-bottom: 15px;
}

.styled-table {
  width: 100%;
  border-collapse: separate;
  background-color: $table-background-color;
  border-spacing: 0 3px;
}

.styled-table thead th.tableHeader {
  background-color: $table-header-bg-color;
  padding: 10px 15px;
  border-bottom: 1px solid $table-border-color;
  text-align: left;
  font-weight: bold;
  cursor: pointer;
  color: $table-header-text-color;
}

.styled-table thead th.tableFilter {
  height: auto;
}

.styled-table tbody tr {
  background-color: $table-background-color;
  border-bottom: 10px solid transparent;
}

.styled-table tbody tr:hover {
  background-color: $table-row-hover-bg-color;
}

.styled-table td {
  padding: 12px 15px;
  text-align: center;
  vertical-align: middle;
  border: none;
}

.cell-text {
  margin: 0;
  color: #333;
}

.pagination-end {
  justify-content: end;
}
.pagination {
  display: flex;
  gap: 15px;
}

.page-item {
  display: flex;
  justify-content: center;
  align-items: center;

  .page-link {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .prev-next-button {
    border: none;
    border-radius: 30%;
    padding: 10px;
    color: #393c42;
    background-color: $table-background-color;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: transparent;
    }

    &:disabled {
      color: $pagination-border-color;
      cursor: not-allowed;
    }
  }

  .active-page {
    background-color: $pagination-active-bg;
    color: white;
    border-radius: 30%;
    padding: 10px 15px;
    font-size: 16px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.results-button {
  background-color: white;
  border: 1px solid $pagination-border-color;
  padding: 10px 20px;
  font-size: 14px;
  color: #495057;
  border-radius: 5px;
  cursor: pointer;
}

.page-info {
  font-size: 14px;
  color: #3b82f6;
  background-color: #f0f4ff;
  padding: 6px 12px;
  border-radius: 5px;
  border: 2px solid #cfd8e3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: 900;
  display: inline-block;
  text-align: center;
  font-size: 15px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #e0ecff;
    color: #1d4ed8;
  }
}

.styled-table .status-cell .status-badge-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0; // Ajusta el padding si lo deseas más compacto
}
</style>
