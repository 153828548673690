import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"



export default /*@__PURE__*/_defineComponent({
  __name: 'RoundedIcon',
  props: {
  icon: {
    type: String,
    required: true,
  },
  fontSize: {
    type: String,
    required: false,
  },
  width: {
    type: String,
    required: false,
  },
  bordered: {
    type: Boolean,
    required: false,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      fontSize: props.fontSize,
      width: props.width,
      height: props.width,
    }),
    class: _normalizeClass(`iconContainer bgWhite borderRadiusCircle d-flex justify-content-center align-items-center ${
      props.bordered ? 'border' : ''
    }`)
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(`bi ${props.icon}`)
    }, null, 2)
  ], 6))
}
}

})