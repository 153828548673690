<template>
  <InputMaster
    v-if="
      !['catalog', 'action'].includes(columnHeader.columnType) &&
      !columnHeader.isCustomField
    "
    @confirmValue="emitValue"
    v-bind="inputData"
    :reachableValue="true"
  />
</template>

<script lang="ts" setup>
import InputMaster from "@/components/globals/inputs/InputMaster.vue";
import { generateFilterField } from "@/shared/globals/tables/helpers/tables.helper";
import { PropType, computed, defineEmits, defineProps } from "vue";
import { TableHeader } from "../../../shared/globals/tables/interfaces/TableHeader.interface";

const props = defineProps({
  columnHeader: { type: Object as PropType<TableHeader>, required: true },
});

const emit = defineEmits(["confirmValue"]);

const inputData = computed(() => {
  return generateFilterField(props.columnHeader);
});

function emitValue(value) {
  emit("confirmValue", {
    value: value.value,
    path: props.columnHeader.isCustomField
      ? `customFields.${props.columnHeader.mappedKey}`
      : props.columnHeader.mappedKey,
  });
}
</script>
