import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row h-100" }
const _hoisted_2 = { class: "col-auto pl-0" }
const _hoisted_3 = { class: "col h-100" }
const _hoisted_4 = { class: "container-fluid p-0" }
const _hoisted_5 = { class: "row p-0" }
const _hoisted_6 = {
  class: "col",
  id: "headerContainer"
}
const _hoisted_7 = { class: "row p-0" }
const _hoisted_8 = {
  class: "col p-0",
  id: "contentsViewer"
}

import HeaderViews from "@/components/globals/header/HeaderViews.vue";
import HomeSidebar from "@/components/home/HomeSidebar.vue";
import { useAuthServices } from "@/composables/useAuthServices";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { onBeforeMount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const { setDefaultLang } = useLanguageServices();
const { getUser } = useAuthServices();
const router = useRouter();

const darkMode = ref(false);

function setDarkMode(event) {
  darkMode.value = event._value;
}

onBeforeMount(() => {
  if (
    localStorage.getItem(
      `${window.location.hostname.split(".")[0]}SmartTalentAccessToken`
    )
  ) {
    getUser();
  }
  setDefaultLang();
});

onMounted(() => {
  document.getElementById("sidebarCollapse")?.addEventListener("click", () => {
    document.getElementById("sidebar")?.classList.toggle("active");
  });
  router.push("/dashboard");
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`container-fluid bgLight h-100vh ${
      darkMode.value == true ? 'darkMode' : ''
    }`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(HomeSidebar, {
          onDarkModeToggle: _cache[0] || (_cache[0] = ($event: any) => (setDarkMode($event)))
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(HeaderViews, { "search-bar": true })
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_router_view)
            ])
          ])
        ])
      ])
    ])
  ], 2))
}
}

})