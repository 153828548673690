import { SidebarElement } from "../types/SidebarElements.type";

export const SidebarElements: SidebarElement[] = [
  {
    name: "home",
    icon: "bi bi-columns-gap",
    type: "element",
    i18nName: "home.navbar.elements.home",
    action: { route: "/dashboard", metadata: { name: "dashboard" } },
  },
  {
    name: "talentManager",
    icon: "bi bi-person",
    type: "element",
    i18nName: "home.navbar.elements.talentManager",
    action: { route: "/talent", metadata: { name: "talent" } },
  },
  {
    name: "onAndOffBoarding",
    icon: "bi bi-dropbox",
    type: "element",
    i18nName: "home.navbar.elements.onAndOffBoarding",
    action: { route: "/", metadata: {} },
  },
  {
    name: "performance",
    icon: "bi bi-award",
    type: "element",
    i18nName: "home.navbar.elements.performance",
    action: { route: "/", metadata: {} },
  },
  {
    name: "learningAndDevelopment",
    icon: "bi bi-backpack2",
    type: "element",
    i18nName: "home.navbar.elements.learningAndDevelopment",
    action: { route: "/", metadata: {} },
  },
  {
    name: "compensations",
    icon: "bi bi-chat-left",
    type: "element",
    i18nName: "home.navbar.elements.compensations",
    action: { route: "/", metadata: {} },
  },
];
