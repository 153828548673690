<template>
  <div class="row">
    <div class="col-12">
      <GeneralForm ref="formMain" :form="formMainTemplate" formName="create">
        <div class="row">
          <div
            class="col-12 d-flex justify-content-center"
            v-if="
              formMain?.values.customField.type === 'select' ||
              formMain?.values.customField.type === 'multiple-select'
            "
          >
            <ButtonField :buttonField="buttonAdd"></ButtonField>
          </div>
        </div>
      </GeneralForm>
    </div>
    <div class="col-12">
      <GeneralModal
        :modalId="`settingsModal${formMain?.formId}`"
        :modalName="$t('customFields.settings')"
        data-bs-backdrop="false"
        class="p-4"
      >
        <GeneralForm
          v-if="formMain?.values?.customField?.type === 'text'"
          :form="formTextSettingsTemplate"
          formName="settings"
          ref="settingsForm"
        >
        </GeneralForm>
        <GeneralForm
          v-if="formMain?.values?.customField?.type === 'number'"
          :form="formNumberSettingsTemplate"
          formName="settings"
          ref="settingsForm"
        >
        </GeneralForm>
        <GeneralForm
          v-if="formMain?.values?.customField?.type === 'date'"
          :form="formDateSettingsTemplate"
          formName="settings"
          ref="settingsForm"
        >
        </GeneralForm>
        <GeneralForm
          v-if="formMain?.values?.customField?.type === 'multiple-select'"
          :form="formSelectSettingsTemplate"
          formName="settings"
          ref="settingsForm"
        >
        </GeneralForm>
      </GeneralModal>
    </div>
    <div class="col-12">
      <GeneralModal
        modalId="translateModal"
        ref="translateModal"
        :modalName="`${$t('customFields.translates')}`"
        data-bs-backdrop="false"
        class="p-4"
      >
        <GeneralForm
          :form="formTranslateTemplate"
          formName="translatesForm"
          ref="translateForm"
          v-if="formTranslateTemplate"
        >
        </GeneralForm>
      </GeneralModal>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useValidations } from "@/composables/useValidations";
import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import {
  Form,
  FormSection,
} from "@/shared/globals/forms/interfaces/Form.interface";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";
import { Modal } from "bootstrap";
import { merge } from "lodash";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ButtonField from "../globals/buttons/ButtonField.vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { addNameTranslate } from "./forms/templates/AddNameTranslate.template";
import { addOptionTranslate } from "./forms/templates/AddOptionTranslate.template";
import { createOrUpdateCustomField as createCustomFieldTemplate } from "./forms/templates/CreateOrUpdateCustomField.template";
import { createOrUpdateDateSettings } from "./forms/templates/CreateOrUpdateDateSettings.template";
import { createOrUpdateNumberSettings } from "./forms/templates/CreateOrUpdateNumberSettings.template";
import {
  buttonDelete,
  buttonTranslate,
  createOrUpdateMultipleSelectSettings,
  fieldCreate,
} from "./forms/templates/CreateOrUpdateSelectSettings.template";
import { createOrUpdateTextSettings } from "./forms/templates/CreateOrUpdateTextSettings.template";

const validations = useValidations();
const { createCustomField, updateCustomField } = useCustomFieldsServices();
const { t } = useI18n();

const props = defineProps({
  initialValues: { type: Object, required: false },
  isCreating: { type: Boolean, required: true },
});
const emit = defineEmits(["customFieldSubmitted"]);

const formMain = ref();
const settingsForm = ref();
const translateForm = ref();
const formMainTemplate = ref(
  createCustomFieldTemplate(
    submitMainForm,
    formMain,
    validations,
    props.initialValues,
    props.isCreating,
    actionSettings,
    actionTranslate
  )
);

const formSelectSettingsTemplate = ref(
  createOrUpdateMultipleSelectSettings(submitSettingsForm, validations)
);
const formTextSettingsTemplate = ref(
  createOrUpdateTextSettings(t, settingsForm, submitSettingsForm)
);
const formNumberSettingsTemplate = ref(
  createOrUpdateNumberSettings(t, settingsForm, submitSettingsForm)
);
const formDateSettingsTemplate = ref(
  createOrUpdateDateSettings(submitSettingsForm, validations)
);
const formTranslateTemplate = ref();
const allValues: Ref<{ customField: CustomFieldInterface } | object> = ref({});
const translateModal = ref();
const currentIndex = ref();

const settingsFormTrick = computed(() => {
  return settingsForm?.value?.values ?? {};
});

watch(
  settingsFormTrick,
  async () => {
    formTextSettingsTemplate.value = createOrUpdateTextSettings(
      t,
      settingsForm,
      submitSettingsForm
    );
  },
  { immediate: true, deep: true }
);

let currentType;
const buttonAdd: ButtonTemplate = {
  backgroundColor: "#7451c2",
  customClass: "btn ml-1 my-2",
  graphic: {
    icon: {
      icon: "bi bi-plus-circle",
      color: "white",
      backgroundColor: "#7451c2",
    },
  },
  action: () =>
    addOptionField(
      getSection(formMainTemplate, {
        value: "customFields.optionsValues",
        needsTranslate: true,
      })
    ),
  metadata: {
    isAppend: false,
    positionInField: "end",
  },
};

async function actionTranslate() {
  formTranslateTemplate.value = undefined;
  await new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (!translateForm.value) {
        clearInterval(interval);
        resolve(translateForm.value);
      }
    }, 10);
  });
  formTranslateTemplate.value = addNameTranslate(submitTranslateForm);
  await new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (translateForm.value) {
        clearInterval(interval);
        resolve(translateForm.value);
      }
    }, 10);
  });
  if (allValues.value) {
    translateForm.value.setValues(allValues.value);
  }
  const modalInstance = Modal.getOrCreateInstance(translateModal.value.$el);
  modalInstance.show();
}

async function submitSettingsForm() {
  await new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      allValues.value = { ...allValues.value, ...settingsForm.value.values };
      const modalElement = document.getElementById(
        `settingsModal${formMain?.value.formId}`
      );
      const modalInstance = Modal.getInstance(modalElement);
      modalInstance?.hide();
      resolve();
    }, 300);
  });
}

async function submitMainForm() {
  if (props.isCreating) {
    allValues.value = merge({}, allValues.value, formMain.value.values);
    await createCustomField({
      customField: (allValues.value as { customField: CustomFieldInterface })
        .customField,
      callBack: handleCustomFieldSubmit,
    });
  } else {
    allValues.value = merge({}, allValues.value, formMain.value.values);
    await updateCustomField({
      customField: (allValues.value as { customField: CustomFieldInterface })
        .customField,
      callBack: handleCustomFieldSubmit,
    });
  }
}

async function submitTranslateForm() {
  await new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      allValues.value = merge({}, allValues.value, translateForm.value.values);
      Modal.getInstance(translateModal.value.$el)?.hide();
      resolve();
    }, 300);
  });
}

function actionSettings() {
  const modalElement = document.getElementById(
    `settingsModal${formMain?.value?.formId}`
  );
  let modalInstance = Modal.getInstance(modalElement);
  if (!modalInstance) {
    modalInstance = new Modal(modalElement);
  }
  settingsForm.value?.setValues(allValues.value);
  modalInstance?.show();
}

function handleCustomFieldSubmit() {
  if (props.isCreating) {
    resetForm();
  }
  emit("customFieldSubmitted");
}

function resetForm() {
  formMain.value?.setValues({ customField: { name: "", type: "" } });
  allValues.value = {};
}

function getSection(form: Ref<Form>, sectionName: LabelType) {
  return form.value.sections.find((e) => {
    return JSON.stringify(e.name) === JSON.stringify(sectionName);
  });
}

function getSectionIndex(form: Ref<Form>, sectionName: LabelType) {
  return form.value.sections.findIndex((e) => {
    return JSON.stringify(e.name) === JSON.stringify(sectionName);
  });
}

function deleteOptionsField(indexOptionField) {
  const inputName = `customFieldOption.${indexOptionField}`;
  const sectionIndex = getSectionIndex(formMainTemplate, {
    value: "customFields.optionsValues",
    needsTranslate: true,
  });
  if (sectionIndex > -1) {
    const fieldIndex = formMainTemplate.value.sections[
      sectionIndex
    ].fields.findIndex((f) => f.inputName === inputName);
    if (fieldIndex > -1) {
      formMainTemplate.value.sections[sectionIndex].fields[
        fieldIndex
      ].conditions = () => false;

      formMainTemplate.value.sections[sectionIndex].fields[
        fieldIndex
      ].conditions = () => false;

      const translates = (
        allValues.value as {
          customField: CustomFieldInterface;
        }
      ).customField?.metadata?.language?.options;
      Object.entries(translates ?? {}).forEach((translate) => {
        const [lang] = translate;
        delete translates[lang][`${indexOptionField}`];
      });

      const options = (
        allValues.value as {
          customField: CustomFieldInterface;
        }
      ).customField?.metadata?.options;
      if (options) {
        delete options[`${indexOptionField}`];
      }
    }
  }
}

function addOptionField(
  section: FormSection,
  indexOptionField?: string,
  optionKeys?: string[],
  removable = true,
  propsField?
) {
  let lastIndex;
  if (indexOptionField) {
    lastIndex = indexOptionField.split("_")[1];
  } else {
    const lasField = section.fields.at(-1);
    lastIndex = lasField?.path.split("._")[1] ?? -1;
    lastIndex = Number(lastIndex) + 1;
    indexOptionField = `_${lastIndex}`;
  }

  let optionField: Field = fieldCreate(validations);
  optionField = {
    ...optionField,
    path: `${optionField.path}${indexOptionField}`,
    inputName: `${optionField.inputName}${indexOptionField}`,
    label: null,
    placeholder: {
      value: `${optionField.placeholder.value}`,
      params: { optionIndex: `${lastIndex}` },
      needsTranslate: true,
    },
    ...(propsField ? propsField : {}),
    buttons: [
      {
        ...buttonTranslate,
        action: async () => {
          currentIndex.value = indexOptionField;
          const modalInstance = Modal.getOrCreateInstance(
            translateModal.value.$el
          );
          formTranslateTemplate.value = undefined;
          await new Promise((resolve, reject) => {
            const interval = setInterval(() => {
              if (!translateForm.value) {
                clearInterval(interval);
                resolve(translateForm.value);
              }
            }, 10);
          });
          formTranslateTemplate.value = addOptionTranslate(
            submitTranslateForm,
            validations,
            indexOptionField
          );
          await new Promise((resolve, reject) => {
            const interval = setInterval(() => {
              if (translateForm.value) {
                clearInterval(interval);
                resolve(translateForm.value);
              }
            }, 100);
          });
          if (allValues.value) {
            translateForm.value.setValues(allValues.value);
          }
          modalInstance.show();
        },
      },
      {
        ...buttonDelete,
        ...(!removable
          ? { customClass: `${buttonDelete.customClass} disabled` }
          : {}),
        action: () => {
          deleteOptionsField(indexOptionField);
        },
      },
    ],
  };
  section.fields.push(optionField);
}

function resetOptionsField() {
  const section = getSection(formMainTemplate, {
    value: "customFields.optionsValues",
    needsTranslate: true,
  });
  section.fields = [];
  addOptionField(section, null, null, false, {
    label: {
      value: "customFields.optionsValues",
      needsTranslate: true,
    },
  });
}

onMounted(() => {
  const { values: valuesFormMain } = formMain.value;
  watch(valuesFormMain, () => {
    const type = valuesFormMain.customField.type;
    if (currentType && currentType !== type) {
      allValues.value = {};
    }
    currentType = type;
  });
});

onMounted(() => {
  if (props.initialValues) {
    allValues.value = {
      customField: {
        id: props.initialValues?.customField?.id,
        metadata: {
          fieldSettings:
            props.initialValues?.customField?.metadata?.fieldSettings,
        },
      },
    };
    formMain.value?.setValues({
      customField: {
        name: props.initialValues?.customField?.name,
        type: props.initialValues?.customField?.type,
        required: props.initialValues?.customField?.required,
        metadata: {
          options: props.initialValues?.customField?.metadata?.options,
        },
      },
    });
    if (
      props.initialValues?.customField.type === "select" ||
      props.initialValues?.customField.type === "multiple-select"
    ) {
      const options = Object.keys(
        props.initialValues?.customField.metadata?.options
      );
      options.forEach((option) => {
        setTimeout(
          () =>
            addOptionField(
              getSection(formMainTemplate, {
                value: "customFields.optionsValues",
                needsTranslate: true,
              }),
              option,
              options
            ),
          50
        );
      });
      allValues.value = { ...allValues.value, ...props.initialValues };
    }
  } else {
    resetOptionsField();
    formMain.value?.setFieldValue("customField.required", false);
    formMain.value?.setFieldValue("customField.type", "");
  }
});

defineExpose({
  resetForm,
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
