import { merge, mergeWith } from "lodash";
import * as yup from "yup";
import { MethodYup } from "../yup/types/MethodYup.type";
import { RecursiveMethodYupCollection } from "../yup/types/RecursiveMethodYupCollection.type";
import { RecursiveYupCollection } from "../yup/types/RecursiveYupCollection.type";
import { RecursiveErrorsMessage } from "../yup/types/RecursiveErrorsMessage.type";

const joinArrays = (objValue, srcValue) => {
  if (Array.isArray(objValue) && Array.isArray(srcValue)) {
    return objValue.concat(srcValue); // Combina los arrays
  } else if (Array.isArray(objValue)) {
    return objValue;
  } else if (Array.isArray(srcValue)) {
    return srcValue;
  } else if (
    typeof objValue === "object" &&
    objValue !== null &&
    typeof srcValue === "object" &&
    srcValue !== null
  ) {
    return mergeWith(objValue, srcValue, joinArrays);
  }
  return srcValue;
};

export class YupValidator {
  yupCollection: RecursiveYupCollection;
  errors: RecursiveErrorsMessage;

  constructor() {
    this.yupCollection = {};
    this.errors = {};
  }

  addSchema(
    keysValues: RecursiveMethodYupCollection<MethodYup[]>,
    keys: string[]
  ) {
    Object.keys(keysValues).forEach((key) => {
      const newKeysValues = keysValues[key];
      if (Array.isArray(newKeysValues)) {
        let yupObject = yup;
        newKeysValues.forEach((method) => {
          yupObject = yupObject[method.name](...method.params);
          const errorMessage = method.params[1] ? [method.params[1]] : [];
          const error = {};
          error[[...keys, key].join(".")] = errorMessage;
          this.errors = mergeWith(this.errors, error, joinArrays);
        });

        const collection = this.createValueFromKeys([...keys, key], yupObject);
        this.yupCollection = merge(this.yupCollection, collection);
      } else {
        this.addSchema(newKeysValues, [...keys, key]);
      }
    });
  }

  createValueFromKeys(keys: string[], value: any) {
    const acc = {};
    keys.reduce((acc, key, index, arr) => {
      if (index === arr.length - 1) {
        acc[key] = value;
      }
      return acc[key] || (acc[key] = {});
    }, acc);
    return acc;
  }

  getSchema(yupCollection = this.yupCollection) {
    if (yupCollection.__isYupSchema__) {
      return yupCollection;
    }
    const partialYupObject = {};
    Object.keys(yupCollection).forEach((key) => {
      partialYupObject[key] = this.getSchema(yupCollection[key]);
    });
    return yup.object(partialYupObject);
  }

  async getValidations(values) {
    const schema = this.getSchema();
    const results = await schema
      .validate(values, {
        abortEarly: false,
      })
      .catch((err) => {
        return err;
      });
    const currentErrors = results?.inner?.reduce(
      (accumulator, currentValue) => {
        const key = currentValue.path;
        if (!accumulator[key]) {
          accumulator[key] = currentValue.errors;
        } else {
          accumulator[key] = [...accumulator[key], ...currentValue.errors];
        }
        return accumulator;
      },
      {}
    );
    return Object.keys(this.errors).reduce((acc, key) => {
      acc[key] = (this.errors[key] as string[]).map((error) => {
        return {
          error,
          valid:
            currentErrors && currentErrors[key]
              ? !currentErrors[key].find((e) => e === error)
              : true,
        };
      });
      return acc;
    }, {});
  }
}
