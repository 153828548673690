export const en = {
  name: "English",
  global: {
    back: "Back",
    employee: "Employee",
    earnings: "Earnings",
    inputs: {
      ruleName: "Rules",
      rules: {
        upperCaseLimited: "Must contains at least {quantity} capital letters",
        lowerCaseLimited: "Must contains at least {quantity} lowercase letters",
        especialCharactersLimited:
          "Must contains at least {quantity} especial characters",
        numberCharactersLimited:
          "Must contains at least {quantity} number characters",
        minQuantity: "Must contains at least {quantity} characters",
        numberCharacters: "Must be composed of number characters",
        email:
          "Must be a valid email address, E.g. <i>example{'@'}smt360.com</i>",
      },
    },
    tables: {
      resultsPerPage: "Results per page",
    },
    search: "Search",
    unknownError: "An unknown error occurred, please, contact support",
    statuses: {
      active: "Active",
      pending: "Pending",
      deny: "Deny",
      new: "New",
      inactive: "Inactive",
    },
    permissions: {
      jobPosting: "Job Posting",
      applicationManagement: "Reception and Management of Applications",
      candidateFiltering: "Filtering and Candidate Selection Tools",
      interviewManagement: "Interview Management and Candidate Tracking",
      jobListingView: "Job Listing View",
      onlineApplication: "Online Application and Resume Upload",
      onboardingTaskManagement: "Creation and Management of Onboarding Tasks",
      orientationPrograms: "Orientation and Initial Training Programs",
      employeeExitProcesses: "Employee Exit Processes",
      performanceEvaluations: "Performance Evaluations",
      objectivesManagement: "Management of Objectives and Results",
      developmentPlans: "Individual Development Plans and Tracking",
      payrollAdministration: "Payroll Administration",
      benefitsManagement: "Management of Benefits and Rewards",
      benchmarkingTools: "Analysis and Benchmarking Tools",
      timeTracking: "Time and Attendance Tracking",
      shiftManagement: "Shift and Absence Management",
      communicationTools: "Internal Communication Tools",
      conflictManagement: "Conflict Management and Case Tracking",
      climateSurveys: "Work Climate Surveys and Follow-Up",
      wellBeingPrograms: "Well-Being Programs",
      workplaceSafety: "Workplace Safety Management",
      incidentTracking: "Incident Tracking",
      contractManagement: "Contract and Labor Documentation Management",
      documentFiling: "Document Filing and Search",
      kpiDashboards: "KPIs Dashboards",
      customReports: "Custom Reports and Analysis",
      dataVisualization: "Data Visualization Tools",
      postManagement: "Posts and Comments",
      postReactions: "Reactions to Posts",
      forumManagement: "Discussion Forum Management",
    },
  },
  login: {
    title: "Welcome again!",
    name: "Login",
    email: "Email",
    password: "Password",
    newPassword: "New password",
    submit: "Sign in",
    viewsSubmit: "Send",
    request: "Request",
    errors: {
      credentialsUnknown: "Email and password are incorrect, please try again.",
      incorrectValidationData: "Incorrect validation data",
      incorrectForgotPassData:
        "This email is not registered, use a valid email",
      attemptsLimitExceeded:
        "You have tried it so many times, please try again later",
      expiredCode: "The code has expired, try get a new code",
      userNotFound: "An user registered with that email was not found",
    },
    validationSuccess: "Validation success",
    forgotPassSuccess: "We have send a confirmation code to your email!",
    resendValidationSuccess: "We have send a confirmation code to your email!",
    confirmForgotPassSuccess:
      "You have changed your password, you can access now!",
    forgotPassTitle: "Recover your password!",
    forgotPassword: "Forgot your password?",
    validateEmailTitle: "Validate your email!",
    validateEmail: "Validate your email",
    resendValidateEmail: "Get new code!",
    confirmationCode: "Confirmation code",
    anotherAccessOptions: "You can access with",
    haveACodeYet: "I have a code yet!",
    isValidatedYet: "This email is valid yet",
  },
  accounts: {
    errors: {
      gettingAccount:
        "An error has ocurred while getting account, contact support",
    },
  },
  users: {
    errors: {
      accessExpired: "Your session has expired, sign-in again please",
      unknownError:
        "An unknown error has ocurred while getting user, contact support",
    },
  },
  home: {
    navbar: {
      elements: {
        home: "Home",
        talentManager: "Talent manager",
        onAndOffBoarding: "Onboarding/Offboarding",
        performance: "Performance",
        learningAndDevelopment: "Learning and development",
        compensations: "Compensations",
      },
      exit: "Exit",
      darkMode: "Dark mode",
    },
    dashboard: {
      welcome: "Welcome {userName}!",
      employees: "Employees",
      welcomeMessage:
        "Measure how fast you're growing monthly recurring revenue",
      totalRevenue: "Total Revenue",
      totalExpenses: "Total expenses",
      viewAll: "View all",
      tables: {
        newTalent: {
          tableLabel: "New talents",
          name: "Name",
          id: "ID",
          department: "Department",
          position: "Position",
          requestDate: "Request date",
          amount: "Amount",
          status: "Status",
          actions: "Actions",
          actionList: {
            enable: "Enable",
            disable: "Disable",
            setPending: "Set pending",
          },
        },
      },
    },
    talentManager: {
      requests: "Requests",
      addNew: "Add new",
      tables: {
        talentManagerListUsers: {
          tableLabel: "User list",
          name: "Name",
          position: "Position",
          accessDate: "Access date",
          actions: "Actions",
          status: "Status",
          actionList: {
            delete: "Delete",
            edit: "Edit",
          },
        },
      },
      cards: {},
    },
  },
  catalogs: {
    catalogClasses: {
      keys: {
        USERS_STATUS_CLASS: "Users statuses",
        EMPLOYEES_STATUS_CLASS: "Employees statuses",
      },
    },
  },
};
