<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-10">
        <div class="container" id="formBox">
          <div class="row justify-content-center mb-3">
            <div class="col-auto d-flex align-items-center">
              <h2 class="textBlack">{{ $t("login.validateEmailTitle") }}</h2>
            </div>
          </div>
          <div class="row justify-content-between mb-4">
            <div class="col-auto">
              <i class="bi bi-arrow-left-circle actionableLabel mr-1"></i>
              <span class="actionableLabel" @click="goToLogin()">{{
                $t("login.name")
              }}</span>
            </div>
            <div class="col-auto">
              <span
                class="actionableLabel"
                v-if="isFirstStep"
                @click="isFirstStep = false"
                >{{ $t("login.resendValidateEmail") }}</span
              >
              <span
                v-else
                class="actionableLabel"
                @click="isFirstStep = true"
                >{{ $t("login.haveACodeYet") }}</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col p-0">
              <GeneralForm
                v-if="isFirstStep"
                ref="formRef"
                :form="formValidateEmailStep1"
                formName="validateEmail"
              ></GeneralForm>
              <GeneralForm
                v-else
                ref="formRef"
                :form="formValidateEmailStep2"
                formName="validateEmail"
              ></GeneralForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuthServices } from "@/composables/useAuthServices";
import { useValidations } from "@/composables/useValidations";
import { defineEmits, ref } from "vue";
import { validateEmailStep1 } from "./forms/templates/ValidateEmailStep1.template";
import { validateEmailStep2 } from "./forms/templates/ValidateEmailStep2.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";

const { validateEmail, resendValidateEmail } = useAuthServices();
const validations = useValidations();

const emit = defineEmits(["goToLogin"]);
const isFirstStep = ref<boolean>(true);
const formRef = ref();
const formValidateEmailStep1 = ref(
  validateEmailStep1(submitValidateEmail, validations)
);
const formValidateEmailStep2 = ref(
  validateEmailStep2(submitValidateEmail, validations)
);

function goToLogin(): void {
  isFirstStep.value = true;
  formRef.value.resetForm({});
  emit("goToLogin");
}

async function submitValidateEmail(): Promise<void> {
  const { valid } = formRef.value.meta();
  const values = formRef.value.values();
  if (valid) {
    if (isFirstStep.value) {
      await validateEmail(values, goToLogin);
    } else {
      isFirstStep.value = await resendValidateEmail(values);
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  $labelWith: 35%;
  $inputWith: calc(100% - $labelWith);
  span {
    width: $labelWith;
    text-align: start;
  }
  input {
    width: $inputWith;
  }
}

#btnLogin {
  background-color: $ThirdColor;
  border-color: $ThirdColor;
  color: white;
  &:hover {
    color: $ThirdColor;
    background-color: white;
  }
}
</style>
