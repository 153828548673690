import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid home h-100" }
const _hoisted_2 = { class: "row mb-3" }
const _hoisted_3 = { class: "col-3 d-flex align-items-center" }
const _hoisted_4 = { class: "container-fluid" }
const _hoisted_5 = { class: "row justify-content-start" }
const _hoisted_6 = { class: "col-auto p-0" }
const _hoisted_7 = { class: "row justify-content-start" }
const _hoisted_8 = { class: "col-auto p-0" }
const _hoisted_9 = { class: "m-0 littleFont boldFont" }
const _hoisted_10 = { class: "col-3" }
const _hoisted_11 = { class: "col-3" }
const _hoisted_12 = { class: "col-3" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-9" }
const _hoisted_15 = { class: "container-fluid" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-12 p-0" }
const _hoisted_18 = { class: "w-100 h-100 container-fluid" }
const _hoisted_19 = { class: "row justify-content-end" }
const _hoisted_20 = { class: "col-4" }
const _hoisted_21 = { class: "col-auto" }
const _hoisted_22 = { class: "row justify-content-end pt-2 pb-2" }
const _hoisted_23 = { class: "col-auto" }
const _hoisted_24 = { class: "m-0 textPale" }
const _hoisted_25 = { class: "col-3" }
const _hoisted_26 = { class: "container-fluid" }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "col-12" }
const _hoisted_29 = { class: "w-100 h-100" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "col-12" }
const _hoisted_32 = { class: "w-100 h-100" }
const _hoisted_33 = { class: "row" }
const _hoisted_34 = { class: "col-12" }
const _hoisted_35 = { class: "w-100 h-100" }

import CustomFields from "@/components/customFields/CustomFields.vue";
import CardLabel from "@/components/globals/cards/CardLabel.vue";
import GeneralCard from "@/components/globals/cards/GeneralCard.vue";
import GlobalChart from "@/components/globals/charts/GlobalChart.vue";
import RoundedIcon from "@/components/globals/icons/RoundedIcon.vue";
import BasicInput from "@/components/globals/inputs/BasicInput.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import { useUsersServices } from "@/composables/useUsersServices";
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardView',
  setup(__props) {

const { user } = useUsersServices();

const form = ref();
const customFieldsClosed = () => {
  console.log("Cerrando ");
  form.value.resetForms();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h4", null, _toDisplayString(_ctx.$t("home.dashboard.welcome", {
                    userName: _unref(user)?.employee.firstName,
                  })), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("home.dashboard.welcomeMessage")), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(CardLabel, {
          "top-label": "home.dashboard.employees",
          name: "employeesLabelCard",
          value: { value: 1200, type: 'number' },
          icon: { icon: 'bi bi-card-checklist' }
        })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(CardLabel, {
          "top-label": "home.dashboard.employees",
          name: "totalRevLabelCard",
          value: { value: 824523.0, type: 'currency' },
          icon: { icon: 'bi bi-cash-coin' }
        })
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(CardLabel, {
          "top-label": "home.dashboard.employees",
          name: "totalExpensesLabelCard",
          value: { value: 73635.03, type: 'currency' },
          icon: { icon: 'bi bi-receipt-cutoff' }
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createVNode(GeneralCard, { class: "mb-3" }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createVNode(BasicInput, {
                          name: "searchHeader",
                          placeholder: _ctx.$t('global.search')
                        }, null, 8, ["placeholder"])
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(RoundedIcon, {
                          fontSize: "1.2em",
                          width: "2.1em",
                          icon: "bi-sliders2",
                          bordered: true
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.$t("home.dashboard.viewAll")), 1)
                      ])
                    ]),
                    _cache[0] || (_cache[0] = _createElementVNode("div", {
                      class: "row",
                      style: { height: '18em' }
                    }, null, -1))
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createVNode(GeneralCard, { class: "mb-3" }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_29, [
                    _createVNode(GlobalChart, { chart: 2 })
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              _createVNode(GeneralCard, { class: "mb-3" }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_32, [
                    _createVNode(GlobalChart, { chart: 0 })
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_33, [
            _createElementVNode("div", _hoisted_34, [
              _createVNode(GeneralCard, { class: "mb-3" }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_35, [
                    _createVNode(GlobalChart, { chart: 1 })
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})