import { UserInterface } from "@/store/auth/interfaces/User.interface";
import { CatalogClassesKeys } from "@/store/catalogs/const/CatalogClasses.const";
import { CatalogClassInterface } from "@/store/catalogs/interfaces/CatalogClass.interface";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";
import { Menu } from "../../../shared/globals/menu/types/Menu.type";
import { TableHeader } from "../../../shared/globals/tables/interfaces/TableHeader.interface";
import { useLanguageServices } from "@/composables/useLanguageServices";

export const UsersHeader = (
  optionEdit,
  optionDelete,
  optionDisable,
  optionEnable,
  customFields: CustomFieldInterface[]
): TableHeader[] => {
  const customFieldsHeaders: TableHeader[] = customFields.map(
    (customField: CustomFieldInterface) => {
      return {
        sortable: false,
        value: { value: customField.name, needsTranslate: false },
        key: customField.id,
        mappedKey: customField.id,
        columnType: customField.type,
        width: "380px",
        ...(customField?.metadata
          ? { metadata: { customFieldMetadata: customField.metadata } }
          : {}),
        isCustomField: true,
      } as TableHeader;
    }
  );
  const { lang } = useLanguageServices();
  console.log();
  return [
    {
      sortable: true,
      value: { value: "users.tables.profilePic", needsTranslate: true },
      key: "profilePic",
      mappedKey: "employee.profilePic",
      columnType: "text",
      width: "380px",
    },
    {
      sortable: true,
      value: { value: "users.tables.id", needsTranslate: true },
      key: "id",
      mappedKey: "employee.id",
      columnType: "number",
      width: "380px",
    },
    {
      sortable: true,
      value: { value: "users.tables.name", needsTranslate: true },
      key: "firstName",
      mappedKey: "employee.firstName",
      columnType: "text",
      width: "380px",
    },
    {
      sortable: true,
      value: { value: "users.tables.surname", needsTranslate: true },
      key: "lastName",
      mappedKey: "employee.lastName",
      columnType: "text",
      width: "380px",
    },
    {
      sortable: true,
      value: { value: "users.tables.dni", needsTranslate: true },
      key: "dni",
      mappedKey: "employee.dni",
      columnType: "text",
      width: "380px",
    },
    {
      sortable: true,
      value: { value: "users.tables.email", needsTranslate: true },
      key: "email",
      mappedKey: "employee.email",
      columnType: "text",
      width: "380px",
    },
    {
      sortable: true,
      value: { value: "users.tables.hireDate", needsTranslate: true },
      key: "hireDate",
      mappedKey: "employee.hireDate",
      columnType: "text",
      width: "380px",
    },
    {
      sortable: true,
      value: { value: "users.tables.birthDate", needsTranslate: true },
      key: "birthDate",
      mappedKey: "employee.birthDate",
      columnType: "text",
      width: "380px",
    },
    {
      sortable: true,
      value: { value: "users.tables.status", needsTranslate: true },
      key: "status",
      mappedKey: `status.catalogMetadata.name.language.${lang.value}`,
      columnType: "text",
      width: "120px",
    },
    ...customFieldsHeaders,
    {
      sortable: true,
      value: { value: "users.tables.actions", needsTranslate: true },
      columnType: "action",
      options: (user: UserInterface, index: number): Menu => {
        let options = [
          {
            label: "users.tables.actionList.edit",
            id: 1,
            action: () => optionEdit(user, index),
          },
          {
            label: "users.tables.actionList.delete",
            id: 2,
            action: () => optionDelete(user, index),
          },
        ];
        if (user.status.name === "active") {
          options = [
            ...options,
            {
              label: "users.tables.actionList.disable",
              id: 3,
              action: () => optionDisable(user, index),
            },
          ];
        } else {
          options = [
            ...options,
            {
              label: "users.tables.actionList.enable",
              id: 4,
              action: () => optionEnable(user, index),
            },
          ];
        }
        return {
          options,
        };
      },
      width: "95px",
    },
  ];
};
