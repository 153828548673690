import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row d-flex justify-content-center align-items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "container-fluid" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col labelCol textPale" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-9 valueCol fs-1em" }
const _hoisted_10 = { class: "col-auto" }

import GeneralCard from "@/components/globals/cards/GeneralCard.vue";
import { CardValue } from "@/shared/globals/cards/types/CardValue.type";
import { DefaultIcon } from "@/shared/globals/graphics/types/DefaultIcon.type";
import { DefaultImage } from "@/shared/globals/graphics/types/DefaultImage.type";
import {
  formatNumber,
  formatNumberCurrency,
} from "@/shared/globals/helpers/Number.helper";

import { computed, PropType } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'CardLabel',
  props: {
  icon: { type: Object as PropType<DefaultIcon>, required: false },
  image: { type: Object as PropType<DefaultImage>, required: false },
  topLabel: { type: String, required: true },
  value: { type: Object as PropType<CardValue>, required: true },
  graphicContainerCustomClass: { type: String, required: false },
  name: { type: String, required: true },
},
  setup(__props) {

const props = __props;

const value = computed(() => {
  if (props.value.type === "number") {
    return formatNumber(+props.value.value);
  } else if (props.value.type === "currency") {
    return formatNumberCurrency(+props.value.value);
  }
  return props.value.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GeneralCard, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(`col-auto iconCol textBlue bgBlueSoul ${
              props.graphicContainerCustomClass
                ? props.graphicContainerCustomClass
                : ''
            }`)
          }, [
            (props.icon)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: _normalizeClass(`${props.icon.icon} ${props.icon.customClass}`)
                }, null, 2))
              : (props.image)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: props.image.image,
                    alt: "cardPic",
                    class: _normalizeClass(`cardPic ${props.image.customClass}`)
                  }, null, 10, _hoisted_3))
                : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t(props.topLabel)), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(value.value), 1),
                _createElementVNode("div", _hoisted_10, [
                  _renderSlot(_ctx.$slots, "extraValueSlot")
                ])
              ])
            ])
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-auto p-0 dropdownContainer h-100 d-flex align-self-start" }, [
            _createElementVNode("button", {
              class: "btn dropdown-toggle textPale",
              type: "button",
              "data-bs-toggle": "dropdown",
              "aria-expanded": "false"
            }, [
              _createElementVNode("i", { class: "bi bi-three-dots" })
            ]),
            _createElementVNode("ul", { class: "dropdown-menu" }, [
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  class: "dropdown-item",
                  href: "#"
                }, "Action")
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  class: "dropdown-item",
                  href: "#"
                }, "Another action")
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  class: "dropdown-item",
                  href: "#"
                }, "Something else here")
              ])
            ])
          ], -1))
        ])
      ])
    ]),
    _: 3
  }))
}
}

})