<template>
  <div class="container-fluid home h-100">
    <div class="row mb-3">
      <div class="col-12">
        <GeneralCard>
          <template v-slot:content>
            <div class="container-fluid p-0">
              <div class="row justify-content-between">
                <div class="col-auto d-flex align-items-center">
                  <h5 class="m-0 pl-2">
                    {{ $t("home.talentManager.requests") }}
                  </h5>
                </div>

                <div class="col-auto">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-auto p-0 displayedDataButtonContainer">
                        <button
                          :class="`btn leftButton ${
                            dataDisplayMode === 'list' ? 'activeButton' : ''
                          }`"
                          @click="dataDisplayMode = 'list'"
                        >
                          <i class="bi bi-list-task"></i>
                        </button>
                      </div>

                      <div class="col-auto p-0 displayedDataButtonContainer">
                        <button
                          :class="`btn rightButton ${
                            dataDisplayMode === 'cards' ? 'activeButton' : ''
                          }`"
                          @click="dataDisplayMode = 'cards'"
                        >
                          <i class="bi bi-columns-gap"></i>
                        </button>
                      </div>

                      <div class="col-auto addNewContainer">
                        <button
                          type="button"
                          class="btn ml-1 btn-gear"
                          @click="() => openOffcanvasForm('customField')"
                        >
                          <i class="bi bi-gear"></i>
                        </button>
                        <button
                          type="button"
                          class="btn ml-1 btn-gear"
                          @click="() => openOffcanvasForm('createUser')"
                        >
                          {{ $t("home.talentManager.addNew") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-12">
                  <BasicInput
                    name="searchTalent"
                    :placeholder="$t('global.search')"
                    v-model="searchText"
                  ></BasicInput>
                </div>
              </div>
            </div>
          </template>
        </GeneralCard>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="container-fluid">
          <div class="row" v-if="dataDisplayMode === 'list'">
            <div class="col-12 p-0">
              <GeneralCard class="mb-3">
                <template v-slot:content>
                  <GeneralTable
                    v-if="usersHeaderTable"
                    dataSource="/users/"
                    :filters="tableFilters"
                    :headers="usersHeaderTable"
                    name="usersTable"
                    :label="$t('global.tables.users.label')"
                    ref="userTable"
                  ></GeneralTable>
                </template>
              </GeneralCard>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <GeneralOffcanvas
          canvasId="userCanvas"
          :canvasName="
            formName === 'customField'
              ? $t('customFields.title')
              : formName === 'createUser'
              ? $t('users.form.create')
              : $t('users.form.edit')
          "
          @closed="closedUserForm"
          ref="offCanvasRef"
        >
          <template v-slot:content>
            <CreateOrUpdateUser
              v-if="['createUser', 'updateUser'].includes(formName)"
              ref="form"
              :is-creating="true"
              :initial-values="userTemp"
              @handle-submit="handleUserSubmit"
            >
            </CreateOrUpdateUser>
            <CustomFields
              v-else-if="formName === 'customField'"
              ref="form"
              @custom-field-submitted="handleCustomFieldSubmit"
            ></CustomFields>
          </template>
        </GeneralOffcanvas>
        <ConfirmModal
          :title="confirmModalTitleTranslate"
          ref="confirmationModalRef"
          :message="confirmationQuestion"
          :confirmAction="handleConfirm"
        ></ConfirmModal>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CustomFields from "@/components/customFields/CustomFields.vue";
import GeneralCard from "@/components/globals/cards/GeneralCard.vue";
import BasicInput from "@/components/globals/inputs/BasicInput.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import CreateOrUpdateUser from "@/components/users/forms/CreateOrUpdateUser.vue";
import { UsersHeader } from "@/components/users/headers/UsersHeader.headers";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useUsersServices } from "@/composables/useUsersServices";
import { User } from "@/store/auth/models/User.model";
import { Offcanvas } from "bootstrap";
import { computed, onMounted, ref, Ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { enableUser, disableUser, deleteUser } = useUsersServices();
const { getModuleCustomFields, customFields } = useCustomFieldsServices();

type formsName = "createUser" | "updateUser" | "customField";

const dataDisplayMode: Ref<"list" | "cards"> = ref("list");
const searchText: Ref<string> = ref("");
const formName: Ref<"createUser" | "updateUser" | "customField"> = ref();
const confirmModalTitleTranslate: Ref<string> = ref("");
const action: Ref<"edit" | "disable" | "enable" | "delete"> = ref();
const userTemp: Ref<User | null> = ref();
const offCanvasRef = ref();
const confirmationModalRef = ref();
const lastRowIndex: Ref<number> = ref();
const usersHeaderTable = ref();
const tableFilters = ref([]);
const userTable = ref();

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    customFields,
    () => {
      usersHeaderTable.value = UsersHeader(
        optionEdit,
        optionDelete,
        optionDisable,
        optionEnable,
        customFields.value
      );
    },
    { immediate: true, deep: true }
  );
});

const confirmationQuestion = computed(() => {
  return {
    value: `users.${question.value}`,
    params: {
      name: userTemp.value?.name || "",
    },
    needsTranslate: true,
  };
});
const question = computed(() => {
  if (action.value === "disable") {
    return "questionDisable";
  } else if (action.value === "enable") {
    return "questionEnable";
  } else if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

function optionEdit(user: User, index: number) {
  openOffcanvasForm("updateUser");
  action.value = "edit";
  userTemp.value = { ...user };
  lastRowIndex.value = index;
}

function optionDisable(user: User, index: number) {
  action.value = "disable";
  confirmModalTitleTranslate.value = t("global.warnings.status");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionEnable(user: User, index: number) {
  action.value = "enable";
  confirmModalTitleTranslate.value = t("users.warnings.status");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionDelete(user: User, index: number) {
  action.value = "delete";
  confirmModalTitleTranslate.value = t("global.warningDelete");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function handleUserSubmit(data) {
  const { user, isCreating } = data;
  if (isCreating) {
    userTable.value.currentData = [user, ...userTable.value.currentData];
  } else {
    userTable.value.currentData[lastRowIndex.value] = user;
  }
  closeOffcanvasForm();
}

function handleCustomFieldSubmit() {
  closeOffcanvasForm();
}

function openOffcanvasForm(newFormName: formsName) {
  userTemp.value = null;
  formName.value = newFormName;
  const offcanvasInstance = Offcanvas.getOrCreateInstance(
    offCanvasRef.value.$el
  );
  offcanvasInstance.show();
}

function closeOffcanvasForm() {
  const offcanvasInstance = Offcanvas.getOrCreateInstance(
    offCanvasRef.value.$el
  );
  offcanvasInstance.hide();
}

function closedUserForm() {
  console.log("Cerrado");
}

async function handleConfirm() {
  let user = null;
  if (["enable", "disable"].includes(action.value)) {
    if (action.value === "enable") {
      user = await enableUser(userTemp.value);
    } else {
      user = await disableUser(userTemp.value);
    }
    userTable.value.currentData[lastRowIndex.value] = {
      ...userTemp.value,
      status: { id: user.status.id },
    };
  } else if (action.value === "delete") {
    const userDeleted = await deleteUser(userTemp.value);
    if (userDeleted) {
      userTable.value.currentData = userTable.value.currentData.filter(
        (user: User, index) => {
          return index !== lastRowIndex.value;
        }
      );
    }
  }
}
</script>

<style lang="scss" scoped>
.addNewContainer {
  button {
    background-color: #00e097;
    color: #fff;
  }
}

.displayedDataButtonContainer:hover {
  button {
    background-color: $ActiveBlue;
    color: #fff;
  }
}
</style>
