<template>
  <GeneralCard>
    <template v-slot:content
      ><div class="container">
        <div class="row d-flex justify-content-center align-items-center">
          <div
            :class="`col-auto iconCol textBlue bgBlueSoul ${
              props.graphicContainerCustomClass
                ? props.graphicContainerCustomClass
                : ''
            }`"
          >
            <i
              v-if="props.icon"
              :class="`${props.icon.icon} ${props.icon.customClass}`"
            ></i>
            <img
              v-else-if="props.image"
              :src="props.image.image"
              alt="cardPic"
              :class="`cardPic ${props.image.customClass}`"
            />
          </div>
          <div class="col">
            <div class="container-fluid">
              <div class="row">
                <div class="col labelCol textPale">
                  {{ $t(props.topLabel) }}
                </div>
              </div>
              <div class="row">
                <div class="col-9 valueCol fs-1em">
                  {{ value }}
                </div>
                <div class="col-auto"><slot name="extraValueSlot"></slot></div>
              </div>
            </div>
          </div>
          <div
            class="col-auto p-0 dropdownContainer h-100 d-flex align-self-start"
          >
            <button
              class="btn dropdown-toggle textPale"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots"></i>
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li>
                <a class="dropdown-item" href="#">Another action</a>
              </li>
              <li>
                <a class="dropdown-item" href="#">Something else here</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </GeneralCard>
</template>
<script setup lang="ts">
import GeneralCard from "@/components/globals/cards/GeneralCard.vue";
import { CardValue } from "@/shared/globals/cards/types/CardValue.type";
import { DefaultIcon } from "@/shared/globals/graphics/types/DefaultIcon.type";
import { DefaultImage } from "@/shared/globals/graphics/types/DefaultImage.type";
import {
  formatNumber,
  formatNumberCurrency,
} from "@/shared/globals/helpers/Number.helper";

import { computed, defineProps, PropType } from "vue";
const props = defineProps({
  icon: { type: Object as PropType<DefaultIcon>, required: false },
  image: { type: Object as PropType<DefaultImage>, required: false },
  topLabel: { type: String, required: true },
  value: { type: Object as PropType<CardValue>, required: true },
  graphicContainerCustomClass: { type: String, required: false },
  name: { type: String, required: true },
});

const value = computed(() => {
  if (props.value.type === "number") {
    return formatNumber(+props.value.value);
  } else if (props.value.type === "currency") {
    return formatNumberCurrency(+props.value.value);
  }
  return props.value.value;
});
</script>
<style scoped lang="scss">
.iconCol {
  border-radius: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  height: fit-content;
  i {
    height: 24px;
    width: 24px;
    justify-content: center;
    display: flex;
    align-self: center;
    -webkit-text-stroke: 0.7px;
    font-size: 1.5em;
  }
}

.labelCol {
  font-size: 0.9em;
  font-weight: 600;
  display: flex;
  justify-content: start;
}

.valueCol {
  display: flex;
  justify-content: start;
  align-items: center;
  font-weight: bold;
}

.dropdown-toggle {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: start;
  padding: 0;
  font-size: 1.2em;
  i {
    height: 16px;
  }
  &::after {
    display: none !important;
  }
}

.dropdownContainer {
  width: 0;
  position: relative;
  left: -1em;
}
</style>
