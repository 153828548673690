import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onBeforeMount } from "vue";
import { useAuthServices } from "./composables/useAuthServices";
import { useLanguageServices } from "./composables/useLanguageServices";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { getAccount } = useAuthServices();
const { setDefaultLang } = useLanguageServices();

onBeforeMount(() => {
  const hostName = window.location.hostname;
  getAccount({ url: hostName });
  setDefaultLang();
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view))
}
}

})