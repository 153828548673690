import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-fluid tableParentContainer" }
const _hoisted_2 = { class: "row justify-content-between align-items-center mt-3" }
const _hoisted_3 = { class: "col-auto d-flex align-items-center" }
const _hoisted_4 = { class: "dropdown" }
const _hoisted_5 = {
  class: "results-button",
  type: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_6 = { class: "dropdown-menu" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "dropdown-item" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col tableContainer" }
const _hoisted_11 = { class: "w-100 table table-bordered table-hover styled-table" }
const _hoisted_12 = { class: "thead-light" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 0,
  class: "bi bi-arrow-up"
}
const _hoisted_16 = {
  key: 1,
  class: "bi bi-arrow-down"
}
const _hoisted_17 = {
  key: 0,
  style: {"display":"table-caption"},
  class: "container-fluid"
}
const _hoisted_18 = { class: "row justify-content-center" }
const _hoisted_19 = { class: "col-auto mt-3" }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = {
  key: 0,
  class: "cell-text"
}
const _hoisted_23 = {
  key: 1,
  class: "cell-text"
}
const _hoisted_24 = {
  key: 2,
  class: "cell-text"
}
const _hoisted_25 = { key: 3 }
const _hoisted_26 = { key: 4 }
const _hoisted_27 = { key: 1 }
const _hoisted_28 = {
  key: 7,
  class: "cell-text"
}
const _hoisted_29 = { class: "row justify-content-between align-items-center mt-3" }
const _hoisted_30 = { class: "col-auto" }
const _hoisted_31 = { class: "page-info" }
const _hoisted_32 = { class: "col-auto" }
const _hoisted_33 = { "aria-label": "pagination" }
const _hoisted_34 = { class: "pagination justify-content-center align-items-center" }
const _hoisted_35 = { class: "page-item" }
const _hoisted_36 = ["disabled"]
const _hoisted_37 = { class: "page-item" }
const _hoisted_38 = { class: "page-link active-page" }
const _hoisted_39 = { class: "page-item" }
const _hoisted_40 = ["disabled"]

import { useLanguageServices } from "@/composables/useLanguageServices";
import { useTablesServices } from "@/composables/useTablesServices";
import { StatusInterface } from "@/shared/globals/helpers/Catalogs.helper";
//import { formatDate } from "@/shared/globals/helpers/Date.helper";
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { ResultsPerPageOptions } from "@/shared/globals/tables/consts/ResultsPerPage.const";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { UserTag } from "@/shared/globals/tags/types/UserTag.type";
import {
  computed,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import DropdownMenu from "../UiTools/DropdownMenu.vue";
import IsLoading from "../UiTools/IsLoading.vue";
import StatusBadge from "../UiTools/StatusBadge.vue";
import ProfileTag from "../profile/ProfileTag.vue";
import ColumnFilter from "./ColumnFilter.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralTable',
  props: {
  headers: {
    type: Object as PropType<TableHeader[]>,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  dataSource: {
    type: String,
    required: true,
  },
  orderBy: {
    type: String,
    default: "id",
  },
  orderType: {
    type: String as PropType<"asc" | "desc">,
    default: "desc",
  },
},
  setup(__props, { expose: __expose }) {

const { lang } = useLanguageServices();

const props = __props;

const headersRef = computed(() => props.headers);
const orderBy = ref(props.orderBy);
const orderType = ref(props.orderType);
const {
  getTableData,
  currentPage,
  totalPages,
  pageSize,
  mappedData,
  translatedHeaders,
  loadingTable,
  filters,
  currentData,
  applyFilters,
} = useTablesServices(props.dataSource, headersRef, orderBy, orderType);
const isLoading = computed(() => loadingTable.value);

const resultsPerPageOptions = ResultsPerPageOptions;

const pagesList = computed(() => {
  return Array.from({ length: totalPages.value }, (_, i) => i + 1);
});

function changeSort(columnKey: string) {
  if (orderBy.value === columnKey) {
    orderType.value = orderType.value === "asc" ? "desc" : "asc";
  } else {
    orderBy.value = columnKey;
    orderType.value = "asc";
  }
}

function nextPage() {
  if (currentPage.value < totalPages.value) currentPage.value++;
}

function prevPage() {
  if (currentPage.value > 1) currentPage.value--;
}

function getTableRow(row: { [key: string]: unknown }) {
  return translatedHeaders.value.map((header) => {
    const cellData = row[header.key];

    if (header.columnType === "action") {
      return {
        value: { options: header.options },
        type: "action",
        metadata: header.metadata,
      };
    }

    return {
      value: cellData,
      type: header.columnType || "text",
      metadata: header.metadata,
    };
  });
}

watch(
  [orderBy, orderType, currentPage, pageSize, filters],
  () => {
    getTableData();
  },
  { flush: "post" }
);

onMounted(() => {
  getTableData();
});

__expose({ currentData });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[3] || (_cache[3] = _createElementVNode("span", null, "Results: ", -1)),
          _createElementVNode("button", _hoisted_5, [
            _createTextVNode(_toDisplayString(_unref(pageSize)) + " ", 1),
            _cache[2] || (_cache[2] = _createElementVNode("i", { class: "bi bi-caret-down" }, null, -1))
          ]),
          _createElementVNode("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(resultsPerPageOptions), (option) => {
              return (_openBlock(), _createElementBlock("li", {
                key: `page-option-${option}`,
                onClick: ($event: any) => (pageSize.value = option)
              }, [
                _createElementVNode("a", _hoisted_8, _toDisplayString(option), 1)
              ], 8, _hoisted_7))
            }), 128))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("table", _hoisted_11, [
          _createElementVNode("colgroup", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(translatedHeaders), (header) => {
              return (_openBlock(), _createElementBlock("col", {
                key: `col-${props.name}-${header.key}`,
                style: _normalizeStyle({ width: header.width })
              }, null, 4))
            }), 128))
          ]),
          _createElementVNode("thead", _hoisted_12, [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(translatedHeaders), (header) => {
                return (_openBlock(), _createElementBlock("th", {
                  class: _normalizeClass(["tableHeader text-center", { sortable: header.sortable }]),
                  key: `header-${props.name}-${header.key}`,
                  onClick: ($event: any) => (changeSort(header.key))
                }, [
                  _createTextVNode(_toDisplayString(header?.metadata?.customFieldMetadata?.language?.name?.[
                    _unref(lang)
                  ] ?? header.value) + " ", 1),
                  (orderBy.value === header?.key)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                        (orderType.value === 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_15))
                          : _createCommentVNode("", true),
                        (orderType.value === 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_16))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ], 10, _hoisted_13))
              }), 128))
            ])
          ]),
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headersRef.value, (header) => {
                return (_openBlock(), _createElementBlock("th", {
                  class: "tableFilter text-center",
                  key: `header-filter-${props.name}-${header.key}`
                }, [
                  _createVNode(ColumnFilter, {
                    columnHeader: header,
                    onConfirmValue: _unref(applyFilters)
                  }, null, 8, ["columnHeader", "onConfirmValue"])
                ]))
              }), 128))
            ])
          ]),
          (_unref(loadingTable) || _unref(mappedData).length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    (_unref(loadingTable))
                      ? (_openBlock(), _createBlock(IsLoading, { key: 0 }))
                      : (_openBlock(), _createElementBlock("h5", _hoisted_20, _toDisplayString(_ctx.$t("global.tables.emptyData")), 1))
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("tbody", _hoisted_21, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mappedData), (row, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: `row-${props.name}-${index}`,
                    class: "tableRow"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getTableRow(row), (data, dataIndex) => {
                      return (_openBlock(), _createElementBlock("td", {
                        key: `cell-${props.name}-${index}-${dataIndex}`,
                        class: "text-center"
                      }, [
                        (data.type === 'text')
                          ? (_openBlock(), _createElementBlock("p", _hoisted_22, _toDisplayString(data.value), 1))
                          : (data.type === 'date')
                            ? (_openBlock(), _createElementBlock("p", _hoisted_23, _toDisplayString(data.value), 1))
                            : (data.type === 'multiple-select')
                              ? (_openBlock(), _createElementBlock("p", _hoisted_24, _toDisplayString(data.value), 1))
                              : (
                    data.type === 'permission' && Array.isArray(data.value)
                  )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((data.value as Array<string>), (permission, index) => {
                                      return (_openBlock(), _createElementBlock("span", {
                                        key: `${permission}.${index}`
                                      }, _toDisplayString(`${_ctx.$t(`global.permissions.${permission}`)}${
                        index < data.value.length - 1 ? ", " : ""
                      }`), 1))
                                    }), 128))
                                  ]))
                                : (data.type === 'catalog')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                      (((data.value) as StatusInterface).bgColor && ((data.value) as StatusInterface).color)
                                        ? (_openBlock(), _createBlock(StatusBadge, {
                                            key: 0,
                                            status: data.value as StatusInterface
                                          }, null, 8, ["status"]))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(data.value), 1))
                                    ]))
                                  : (data.type === 'userTag')
                                    ? (_openBlock(), _createBlock(ProfileTag, {
                                        key: 5,
                                        userData: data.value as UserTag,
                                        showEmail: data.metadata?.showEmail
                                      }, null, 8, ["userData", "showEmail"]))
                                    : (data.type === 'action')
                                      ? (_openBlock(), _createBlock(DropdownMenu, {
                                          key: 6,
                                          menuData: (data.value.options(_unref(currentData)[index],index) as Menu),
                                          name: `menu-${index}-${dataIndex}`
                                        }, null, 8, ["menuData", "name"]))
                                      : (_openBlock(), _createElementBlock("p", _hoisted_28, _toDisplayString(data.value), 1))
                      ]))
                    }), 128))
                  ]))
                }), 128))
              ]))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_29, [
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("span", _hoisted_31, "Showing page " + _toDisplayString(_unref(currentPage)) + " of " + _toDisplayString(_unref(totalPages)), 1)
      ]),
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("nav", _hoisted_33, [
          _createElementVNode("ul", _hoisted_34, [
            _createElementVNode("li", _hoisted_35, [
              _createElementVNode("button", {
                class: "prev-next-button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (prevPage())),
                disabled: _unref(currentPage) === 1
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("i", { class: "bi bi-arrow-left" }, null, -1)
              ]), 8, _hoisted_36)
            ]),
            _createElementVNode("li", _hoisted_37, [
              _createElementVNode("span", _hoisted_38, _toDisplayString(_unref(currentPage)), 1)
            ]),
            _createElementVNode("li", _hoisted_39, [
              _createElementVNode("button", {
                class: "prev-next-button",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (nextPage())),
                disabled: _unref(currentPage) === _unref(totalPages)
              }, _cache[5] || (_cache[5] = [
                _createElementVNode("i", { class: "bi bi-arrow-right" }, null, -1)
              ]), 8, _hoisted_40)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})