<template>
  <router-view />
</template>

<script lang="ts" setup>
import { onBeforeMount } from "vue";
import { useAuthServices } from "./composables/useAuthServices";
import { useLanguageServices } from "./composables/useLanguageServices";

const { getAccount } = useAuthServices();
const { setDefaultLang } = useLanguageServices();

onBeforeMount(() => {
  const hostName = window.location.hostname;
  getAccount({ url: hostName });
  setDefaultLang();
});
</script>

<style lang="scss">
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
