import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import * as CanvasJS from "@canvasjs/charts";
import { onMounted, ref, Ref } from "vue";
import data from "../../../assets/server-metrics.json";

export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalChart',
  props: {
  chart: {
    type: Number,
  },
},
  setup(__props) {

const props = __props;

const chart: Ref<any | null> = ref(null);
const styleOptions = ref({
  width: "100%",
  height: "250px",
});

const userDps = ref([]),
  systemDps = ref([]),
  buffersDps = ref([]),
  cacheDps = ref([]),
  usedDps = ref([]),
  waitDps = ref([]),
  writeDps = ref([]),
  readDps = ref([]),
  toolTip = ref({
    shared: true,
  }),
  legend = ref({
    cursor: "pointer",
    itemclick: function (e) {
      if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      } else {
        e.dataSeries.visible = true;
      }
      e.chart.render();
    },
  }),
  inboundDps = ref([]),
  outboundDps = ref([]),
  jsonData = ref(data);

const options = ref([
  {
    zoomEnabled: true,
    title: {
      text: "Try Zooming & Panning",
    },
    data: [
      {
        type: "line",
        dataPoints: generateRandomData(),
      },
    ],
  },
  {
    animationEnabled: true,
    exportEnabled: true,
    title: {
      text: "Population Pyramid - United Kingdom",
      fontFamily: "Tahoma, Verdana, sans-serif",
    },
    axisY: {
      labelFormatter: (e) => {
        var suffixes = ["", "K", "M", "B"];
        var order = Math.max(
          Math.floor(Math.log(Math.abs(e.value)) / Math.log(1000)),
          0
        );
        if (order > suffixes.length - 1) order = suffixes.length - 1;
        var suffix = suffixes[order];
        return Math.abs(e.value) / Math.pow(1000, order) + suffix;
      },
    },
    toolTip: {
      shared: true,
      contentFormatter: (e) => {
        var content =
          "<span style='font-weight: bold;'>Age Group: " +
          e.entries[0].dataPoint.label +
          "</span><br/>";
        for (var i = 0; i < e.entries.length; i++) {
          content +=
            "<span style='color:" +
            e.entries[i].dataSeries.color +
            "'>" +
            e.entries[i].dataSeries.name +
            "</span>: " +
            CanvasJS.formatNumber(
              Math.abs(e.entries[i].dataPoint.y),
              "#,##0.##"
            );
          content += "<br/>";
        }
        return content;
      },
    },
    legend: {
      verticalAlign: "top",
    },
    data: [
      {
        type: "stackedBar",
        showInLegend: true,
        name: "♂️Male",
        legendMarkerType: "none",
        color: "#00a6ed",
        dataPoints: [
          { label: "0-4", y: -2009363 },
          { label: "5-9", y: -2108550 },
          { label: "10-14", y: -2022370 },
          { label: "15-19", y: -1880611 },
          { label: "20-24", y: -2072674 },
          { label: "25-29", y: -2275138 },
          { label: "30-34", y: -2361054 },
          { label: "35-39", y: -2279836 },
          { label: "40-44", y: -2148253 },
          { label: "45-49", y: -2128343 },
          { label: "50-54", y: -2281421 },
          { label: "55-59", y: -2232388 },
          { label: "60-64", y: -1919839 },
          { label: "65-69", y: -1647391 },
          { label: "70-74", y: -1624635 },
          { label: "75-79", y: -1137438 },
          { label: "80-84", y: -766956 },
          { label: "85-89", y: -438663 },
          { label: "90-94", y: -169952 },
          { label: "95-99", y: -34524 },
          { label: "100+", y: -3016 },
        ],
      },
      {
        type: "stackedBar",
        showInLegend: true,
        name: "♀️Female",
        legendMarkerType: "none",
        color: "#f70a8d",
        dataPoints: [
          { label: "0-4", y: 1915127 },
          { label: "5-9", y: 2011016 },
          { label: "10-14", y: 1933970 },
          { label: "15-19", y: 1805522 },
          { label: "20-24", y: 2001966 },
          { label: "25-29", y: 2208929 },
          { label: "30-34", y: 2345774 },
          { label: "35-39", y: 2308360 },
          { label: "40-44", y: 2159877 },
          { label: "45-49", y: 2167778 },
          { label: "50-54", y: 2353119 },
          { label: "55-59", y: 2306537 },
          { label: "60-64", y: 1985177 },
          { label: "65-69", y: 1734370 },
          { label: "70-74", y: 1763853 },
          { label: "75-79", y: 1304709 },
          { label: "80-84", y: 969611 },
          { label: "85-89", y: 638892 },
          { label: "90-94", y: 320625 },
          { label: "95-99", y: 95559 },
          { label: "100+", y: 12818 },
        ],
      },
    ],
  },
  {
    animationEnabled: true,
    theme: "light2", // "light1", "light2", "dark1", "dark2"
    title: {
      text: "CPU Utilization",
    },
    toolTip: toolTip.value,
    axisY: {
      valueFormatString: "#0.#%",
    },
    legend: legend.value,
    data: [
      {
        type: "splineArea",
        showInLegend: "true",
        name: "User",
        yValueFormatString: "#0.#%",
        color: "#80DEEA",
        xValueType: "dateTime",
        xValueFormatString: "DD MMM YY HH:mm",
        legendMarkerType: "square",
        dataPoints: userDps.value,
      },
      {
        type: "splineArea",
        showInLegend: "true",
        name: "System",
        yValueFormatString: "#0.#%",
        color: "#26C6DA",
        xValueType: "dateTime",
        xValueFormatString: "DD MMM YY HH:mm",
        legendMarkerType: "square",
        dataPoints: systemDps.value,
      },
      {
        type: "splineArea",
        showInLegend: "true",
        name: "Wait",
        yValueFormatString: "#0.#%",
        color: "#00ACC1",
        xValueType: "dateTime",
        xValueFormatString: "DD MMM YY HH:mm",
        legendMarkerType: "square",
        dataPoints: waitDps.value,
      },
    ],
  },
  {
    animationEnabled: true,
    theme: "light2",
    title: {
      text: "Network Traffic",
    },
    axisY: {
      suffix: " Kb/s",
    },
    toolTip: toolTip,
    legend: legend,
    data: [
      {
        type: "splineArea",
        showInLegend: "true",
        name: "Outbound",
        color: "#C5E1A5",
        xValueType: "dateTime",
        xValueFormatString: "DD MMM YY HH:mm",
        yValueFormatString: "#.## Kb/s",
        legendMarkerType: "square",
        dataPoints: outboundDps,
      },
      {
        type: "splineArea",
        showInLegend: "true",
        name: "Inbound",
        color: "#43A047",
        xValueType: "dateTime",
        xValueFormatString: "DD MMM YY HH:mm",
        yValueFormatString: "#.## Kb/s",
        legendMarkerType: "square",
        dataPoints: inboundDps,
      },
    ],
  },
]);

function syncCharts(syncToolTip, syncCrosshair, syncAxisXRange) {
  const onToolTipUpdated = function (e) {
    if (chart.value != e.chart)
      chart.value.toolTip.showAtX(e.entries[0].xValue);
  };

  const onToolTipHidden = function (e) {
    if (chart.value != e.chart) chart.value.toolTip.hide();
  };

  const onCrosshairUpdated = function (e) {
    if (chart.value != e.chart) chart.value.axisX[0].crosshair.showAt(e.value);
  };

  const onCrosshairHidden = function (e) {
    if (chart.value != e.chart) chart.value.axisX[0].crosshair.hide();
  };

  const onRangeChanged = function (e) {
    if (e.trigger === "reset") {
      chart.value.options.axisX.viewportMinimum =
        chart.value.options.axisX.viewportMaximum = null;
      chart.value.options.axisY.viewportMinimum =
        chart.value.options.axisY.viewportMaximum = null;
      chart.value.render();
    } else if (chart.value !== e.chart) {
      chart.value.options.axisX.viewportMinimum = e.axisX[0].viewportMinimum;
      chart.value.options.axisX.viewportMaximum = e.axisX[0].viewportMaximum;
      chart.value.render();
    }
  };

  //Sync ToolTip
  if (syncToolTip) {
    if (!chart.value.options.toolTip) chart.value.options.toolTip = {};

    chart.value.options.toolTip.updated = onToolTipUpdated;
    chart.value.options.toolTip.hidden = onToolTipHidden;
  }

  //Sync Crosshair
  if (syncCrosshair) {
    if (!chart.value.options.axisX)
      chart.value.options.axisX = {
        labelAngle: 0,
        valueFormatString: "MMM D",
        crosshair: {
          enabled: true,
          snapToDataPoint: true,
          valueFormatString: "HH:mm",
        },
      };

    chart.value.options.axisX.crosshair.updated = onCrosshairUpdated;
    chart.value.options.axisX.crosshair.hidden = onCrosshairHidden;
  }

  //Sync Zoom / Pan
  if (syncAxisXRange) {
    chart.value.options.zoomEnabled = true;
    chart.value.options.rangeChanged = onRangeChanged;
  }

  chart.value.render();
}

onMounted(() => {
  for (var i = 0; i < jsonData.value.length; i++) {
    systemDps.value.push({
      x: Number(jsonData.value[i].time),
      y: Number(jsonData.value[i].system),
    });
    userDps.value.push({
      x: Number(jsonData.value[i].time),
      y: Number(jsonData.value[i].user),
    });
    waitDps.value.push({
      x: Number(jsonData.value[i].time),
      y: Number(jsonData.value[i].wait),
    });
    buffersDps.value.push({
      x: Number(jsonData.value[i].time),
      y: Number(jsonData.value[i].buffers),
    });
    cacheDps.value.push({
      x: Number(jsonData.value[i].time),
      y: Number(jsonData.value[i].cache),
    });
    usedDps.value.push({
      x: Number(jsonData.value[i].time),
      y: Number(jsonData.value[i].used),
    });
    inboundDps.value.push({
      x: Number(jsonData.value[i].time),
      y: Number(jsonData.value[i].inbound),
    });
    outboundDps.value.push({
      x: Number(jsonData.value[i].time),
      y: Number(jsonData.value[i].outbound),
    });
    writeDps.value.push({
      x: Number(jsonData.value[i].time),
      y: Number(jsonData.value[i].write),
    });
    readDps.value.push({
      x: Number(jsonData.value[i].time),
      y: Number(jsonData.value[i].read),
    });
  }
  syncCharts(true, true, true);
});

function generateRandomData() {
  let y = 100,
    dps = ref([]);
  for (let i = 0; i < 100; i++) {
    y += Math.round(Math.random() * 10 - 5);
    dps.value.push({ y: y });
  }
  return dps;
}
function chartInstance(instance) {
  chart.value = instance;
}

return (_ctx: any,_cache: any) => {
  const _component_CanvasJSChart = _resolveComponent("CanvasJSChart")!

  return (_openBlock(), _createBlock(_component_CanvasJSChart, {
    options: options.value[props.chart],
    style: _normalizeStyle(styleOptions.value),
    onChartRef: chartInstance
  }, null, 8, ["options", "style"]))
}
}

})