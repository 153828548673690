<template>
  <div id="sidebar" :class="`container-fluid ${sidebarClass} pt-2 d-grid`">
    <button
      class="btn bgTransparent"
      id="collapsableButton"
      @click="sidebarOpened = !sidebarOpened"
    >
      <i class="bi bi-caret-right"></i>
    </button>
    <div class="row p-0">
      <div class="col-12 p-0">
        <div class="container-fluid">
          <div
            class="row justify-content-center pb-2 pt-2 align-self-start"
            id="sidebarHeader"
          >
            <div class="col-auto">
              <img src="images/logos/mainLogoShort.png" alt="logo" />
            </div>
          </div>
          <hr />
          <div
            :class="`row pt-3 pb-3 ${
              !sidebarOpened
                ? 'justify-content-center'
                : 'justify-content-around'
            }`"
            id="sidebarUserCard"
          >
            <div class="col-auto pr-0">
              <ProfilePic
                :name="`${user?.employee.firstName} ${user?.employee.lastName}`"
                :image="user?.profilePic"
              ></ProfilePic>
            </div>
            <div class="col-auto collapse collapse-horizontal pl-0">
              <div class="container userDataContainer">
                <div class="row justify-content-start userName">
                  <div class="col-auto p-0">
                    <b>{{ user?.employee.firstName }}</b>
                  </div>
                </div>
                <div
                  class="row justify-content-start userPosition collapse collapse-horizontal"
                >
                  <div class="col-auto p-0">aquí ira la posicion</div>
                </div>
              </div>
            </div>
            <div
              class="col-auto d-flex align-items-center collapse collapse-horizontal"
            >
              <i class="bi bi-gear collapse collapse-horizontal"></i>
            </div>
          </div>
          <hr />
          <div class="row justify-content-center">
            <div class="col-10 p-0">
              <div class="container-fluid">
                <div
                  :class="`row sidebarElement borderRadiusDefault ${
                    !sidebarOpened ? 'justify-content-center' : ''
                  } ${
                    actualParent === element.action.metadata.name
                      ? 'active'
                      : ''
                  }`"
                  v-for="(element, index) in elements"
                  :key="'sidebarElement' + index"
                  @click="navigate(element.action)"
                >
                  <div class="col-2 iconCol pr-0">
                    <i :class="element.icon"></i>
                  </div>
                  <div class="col nameCol collapse collapse-horizontal">
                    <p class="m-0 collapse collapse-horizontal fs-1em">
                      {{ $t(element.i18nName) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-end">
      <div class="col-12">
        <div class="container">
          <div class="row justify-content-center pb-3 extraFunctionsSidebar">
            <div class="col-10 p-0">
              <div class="container-fluid p-0">
                <div :class="`row borderRadiusDefault justify-content-around`">
                  <div class="col-auto pl-3 pr-0 collapse collapse-horizontal">
                    <i class="bi bi-moon"></i>
                  </div>
                  <div
                    class="col-auto d-flex align-items-center collapse collapse-horizontal"
                  >
                    {{ $t("home.navbar.darkMode") }}
                  </div>
                  <div
                    class="col form-check form-switch d-flex justify-content-center align-items-center"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      aria-checked="false"
                      id="flexSwitchDarkMode"
                      v-model="darkMode"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="row justify-content-center pb-3 extraFunctionsSidebar"
            @click="logout()"
          >
            <div class="col-10 p-0">
              <div class="container-fluid p-0">
                <div
                  :class="`row borderRadiusDefault ${
                    sidebarOpened
                      ? 'justify-content-start'
                      : 'justify-content-center'
                  }`"
                >
                  <div
                    :class="`col-auto pl-3 ${sidebarOpened ? 'pr-0' : 'p-0'}`"
                  >
                    <i class="bi bi-door-open"></i>
                  </div>
                  <div
                    class="col-auto d-flex align-items-center collapse collapse-horizontal"
                  >
                    {{ $t("home.navbar.exit") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ProfilePic from "@/components/globals/profile/ProfilePicture.vue";
import { useAuthServices } from "@/composables/useAuthServices";
import { useUsersServices } from "@/composables/useUsersServices";
import { SidebarElements } from "@/shared/dashboard/sidebar/const/SidebarElements.const";
import { RouterActionMenu } from "@/shared/dashboard/sidebar/types/ActionsSidebarElements.type";
import { Collapse } from "bootstrap";
import { computed, defineEmits, onMounted, Ref, ref, watch } from "vue";

import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const { user } = useUsersServices();
const { logout } = useAuthServices();
const elements = ref(SidebarElements);
const sidebarOpened = ref(true);
const collapses: Ref<Collapse[]> = ref([]);

const sidebarClass = computed(() => {
  return sidebarOpened.value ? "open" : "close";
});

const emits = defineEmits(["darkModeToggle"]);

const darkMode = ref(false);

watch(sidebarOpened, (newValue) => {
  collapses.value.forEach((collapsable) => {
    if (newValue) {
      collapsable.show();
    } else {
      collapsable.hide();
    }
  });
});

watch(darkMode, () => {
  emits("darkModeToggle", darkMode);
});

function navigate(routeSidebar: RouterActionMenu) {
  router.push(routeSidebar.route);
}

const actualParent = computed(() => {
  return route?.matched[1]?.name;
});

onMounted(() => {
  const arrayElements = Array.from(document.getElementsByClassName("collapse"));
  arrayElements.forEach((element: Element) => {
    const collapse = new Collapse(element, {
      toggle: false,
    });
    collapse.show();
    collapses.value.push(collapse);
  });
});
</script>

<style lang="scss" scoped>
$closedSidebar: 100px;
$openedSidebar: 300px;
$transitionDuration: 0.6s;

/****BOOTSTRAP VARIABLES*****/
$transition-collapse: height $transitionDuration ease;
$transition-collapse-width: width $transitionDuration ease;
#sidebar {
  width: $closedSidebar;
  height: 100vh;
  overflow-y: auto;
  background-color: white;
  transition: width 0.5s;
  &.open {
    width: $openedSidebar;
    #collapsableButton {
      transform: rotate(0.5turn);
      -webkit-transition: transform $transitionDuration,
        left $transitionDuration;
      -moz-transition: transform $transitionDuration, left $transitionDuration;
      -o-transition: transform $transitionDuration, left $transitionDuration;
      transition: transform $transitionDuration, left $transitionDuration;
      left: calc($openedSidebar - 2vw);
    }
    #sidebarHeader {
      img {
        transition: height $transitionDuration;
        height: 5em;
      }
    }
  }
  &.close {
    width: $closedSidebar;
    #collapsableButton {
      transform: rotate(0turn);
      -webkit-transition: transform $transitionDuration,
        left $transitionDuration;
      -moz-transition: transform $transitionDuration, left $transitionDuration;
      -o-transition: transform $transitionDuration, left $transitionDuration;
      transition: transform $transitionDuration, left $transitionDuration;
      left: calc($closedSidebar - 1.5vw);
    }
    #sidebarHeader {
      img {
        transition: height $transitionDuration;
        height: 2em;
      }
    }
  }

  #collapsableButton {
    font-size: 0.8em;
    position: absolute;
    padding: 2px 3px 2px 3px;
    top: 0;
    transition: left $transitionDuration linear;
  }
}

.sidebarElement {
  height: 2.9em;
  margin-bottom: 0.5em;
  cursor: pointer;
  &:hover,
  &.active {
    background-color: $accentColor;
    color: white;
  }
  .iconCol {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nameCol {
    display: flex;
    justify-content: start;
    align-items: center;
  }
}

.userName,
.userPosition {
  font-size: 0.8em;
}

.userDataContainer {
  display: inline-grid;
  justify-content: center;
  align-items: center;
}

.collapse {
  &:not(.show) {
    display: none !important;
  }
}
.extraFunctionsSidebar {
  color: $GraySolid;
  .borderRadiusDefault {
    padding: 0.5em 0em 0.5em 0em;
    border: solid rgba(216, 216, 216, 0.575) 2px;
    background-color: rgb(247, 247, 247);
  }
}

#flexSwitchDarkMode {
  height: 20px;
  &:active,
  &:focus {
    box-shadow: none !important;
  }
}

i {
  font-size: 1.5em;
}

.extraFunctionsSidebar {
  cursor: pointer;
}

.darkMode {
  #sidebar {
    width: $closedSidebar;
    height: 100vh;
    overflow-y: auto;
    background-color: #1a1a1a; // Fondo oscuro
    transition: width 0.5s;
    &.open {
      width: $openedSidebar;
      #collapsableButton {
        transform: rotate(0.5turn);
        -webkit-transition: transform $transitionDuration,
          left $transitionDuration;
        -moz-transition: transform $transitionDuration, left $transitionDuration;
        -o-transition: transform $transitionDuration, left $transitionDuration;
        transition: transform $transitionDuration, left $transitionDuration;
        left: calc($openedSidebar - 2vw);
      }
      #sidebarHeader {
        img {
          transition: height $transitionDuration;
          height: 5em;
        }
      }
    }
    &.close {
      width: $closedSidebar;
      #collapsableButton {
        transform: rotate(0turn);
        -webkit-transition: transform $transitionDuration,
          left $transitionDuration;
        -moz-transition: transform $transitionDuration, left $transitionDuration;
        -o-transition: transform $transitionDuration, left $transitionDuration;
        transition: transform $transitionDuration, left $transitionDuration;
        left: calc($closedSidebar - 1.5vw);
      }
      #sidebarHeader {
        img {
          transition: height $transitionDuration;
          height: 2em;
        }
      }
    }

    #collapsableButton {
      font-size: 0.8em;
      position: absolute;
      padding: 2px 3px 2px 3px;
      top: 0;
      transition: left $transitionDuration linear;
    }
  }

  .sidebarElement {
    height: 2.9em;
    margin-bottom: 0.5em;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: #333333; // Fondo más claro al hacer hover o activar
      color: #ffffff; // Letra blanca
    }
    .iconCol {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .nameCol {
      display: flex;
      justify-content: start;
      align-items: center;
    }
  }

  .userName,
  .userPosition {
    font-size: 0.8em;
    color: #ffffff; // Letra blanca
  }

  .userDataContainer {
    display: inline-grid;
    justify-content: center;
    align-items: center;
  }

  .collapse {
    &:not(.show) {
      display: none !important;
    }
  }

  .extraFunctionsSidebar {
    color: #ffffff; // Letra blanca
    .borderRadiusDefault {
      padding: 0.5em 0em 0.5em 0em;
      border: solid rgba(96, 96, 96, 0.575) 2px; // Borde gris oscuro
      background-color: #333333; // Fondo oscuro
    }
  }

  #flexSwitchDarkMode {
    height: 20px;
    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }

  i {
    font-size: 1.5em;
    color: #ffffff; // Letra blanca
  }

  .extraFunctionsSidebar {
    cursor: pointer;
  }
}
</style>
