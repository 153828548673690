import { Catalog } from "@/store/catalogs/interfaces/Catalogs.interface";
import { GetCatalogs } from "@/store/catalogs/services/Catalogs.service";
import { useToast } from "./useToastService";
import { t } from "@/shared/locales/services/i18n.services";
import { computed } from "vue";
import { useStore } from "vuex";

export function useCatalogsServices() {
  const store = useStore();

  const catalogs = computed(() => {
    return store?.state?.catalogs?.catalogsClasses ?? [];
  });

  async function getCatalogs() {
    if (catalogs.value.length === 0) {
      await GetCatalogs()
        .then((catalogClass) => {
          console.log("CatalogClass:", catalogClass);
          store.commit("catalogs/setCatalogs", catalogClass);
        })
        .catch(() => {
          useToast().errorToast(t("users.errors.unknownError"));
        });
    }
  }

  function createCatalog(catalog: Catalog) {
    return;
  }
  return { catalogs, getCatalogs };
}
