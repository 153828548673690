import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = { class: "dropdown-menu dropdown-menu-end" }
const _hoisted_3 = ["onClick"]

import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownMenu',
  props: {
  menuData: {
    type: Object as PropType<Menu>,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("button", {
      class: "btn dropdown-toggle textPale",
      type: "button",
      "data-bs-toggle": "dropdown",
      "aria-expanded": "false"
    }, [
      _createElementVNode("i", { class: "bi bi-three-dots" }),
      _createElementVNode("span", { class: "caret" })
    ], -1)),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.menuData.options, (option, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `optionMenu-${index}-${__props.name}`
        }, [
          _createElementVNode("a", {
            onClick: option.action,
            class: "dropdown-item",
            href: "#"
          }, _toDisplayString(_ctx.$t(option.label)), 9, _hoisted_3)
        ]))
      }), 128))
    ])
  ]))
}
}

})