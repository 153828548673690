import AuthView from "@/views/auth/AuthView.vue";
import DashboardView from "@/views/dashboard/DashboardView.vue";
import HomeView from "@/views/HomeView.vue";
import TalentManager from "@/views/talentManager/TalentManager.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: DashboardView,
        meta: { moduleName: "dashboard" },
      },
      {
        path: "/talent",
        name: "talent",
        component: TalentManager,
        meta: { moduleName: "talent" },
      },
    ],
  },
  {
    path: "/auth",
    name: "auth",
    component: AuthView,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const excludedAuthRoutes = ["auth"];

router.beforeEach((to, from, next) => {
  if (
    !localStorage.getItem(
      `${window.location.hostname.split(".")[0]}SmartTalentAccessToken`
    ) &&
    !excludedAuthRoutes.includes(to.name as string)
  ) {
    next("/auth");
  } else {
    next();
  }
});

export default router;
