<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-10">
        <div class="container" id="formBox">
          <div class="row justify-content-center mb-3">
            <div class="col-auto d-flex align-items-center">
              <h2 class="textBlack">{{ $t("login.forgotPassTitle") }}</h2>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-auto">
              <i class="bi bi-arrow-left-circle actionableLabel mr-1"></i>
              <span class="actionableLabel" @click="goToLogin()">{{
                $t("login.name")
              }}</span>
            </div>
            <div class="col-auto">
              <span class="actionableLabel" @click="isFirstStep = false">{{
                $t("login.haveACodeYet")
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col p-0">
              <GeneralForm
                v-if="isFirstStep"
                ref="formRef"
                :form="formForgotPasswordStep1"
                formName="forgotPassword"
              ></GeneralForm>
              <GeneralForm
                v-else
                ref="formRef"
                :form="formForgotPasswordStep2"
                formName="forgotPassword"
              ></GeneralForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuthServices } from "@/composables/useAuthServices";
import { useValidations } from "@/composables/useValidations";
import { defineEmits, ref } from "vue";
import { forgotPasswordStep1 } from "./forms/templates/ForgotPasswordStep1.template";
import { forgotPasswordStep2 } from "./forms/templates/ForgotPasswordStep2.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";

const { forgotPassword, confirmForgotPassword } = useAuthServices();
const validations = useValidations();

const emit = defineEmits(["goToLogin"]);
const isFirstStep = ref<boolean>(true);
const formRef = ref();
const formForgotPasswordStep1 = ref(
  forgotPasswordStep1(submitForgotPassword, validations)
);
const formForgotPasswordStep2 = ref(
  forgotPasswordStep2(submitForgotPassword, validations)
);

async function submitForgotPassword(): Promise<void> {
  const { values } = formRef.value;
  const { email, newPassword, confirmationCode } = values;
  if (isFirstStep.value) {
    isFirstStep.value = !(await forgotPassword({ email }));
  } else {
    await confirmForgotPassword(
      { email, newPassword, confirmationCode },
      goToLogin
    );
  }
}

function goToLogin(): void {
  isFirstStep.value = true;
  formRef.value.resetForm({});
  emit("goToLogin");
}
</script>

<style lang="scss" scoped>
.input-group {
  $labelWith: 35%;
  $inputWith: calc(100% - $labelWith);
  span {
    width: $labelWith;
    text-align: start;
  }
  input {
    width: $inputWith;
  }
}

#btnLogin {
  background-color: $ThirdColor;
  border-color: $ThirdColor;
  color: white;
  &:hover {
    color: $ThirdColor;
    background-color: white;
  }
}
</style>
