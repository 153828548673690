import router from "@/router";
import { ForgotPasswordSteps } from "@/shared/auth/types/ForgotPasswordSteps.type";
import { ValidateEmailType } from "@/shared/auth/types/ValidateEmail.type";
import { AccountUrlLocal } from "@/shared/http/consts/accountUrlLocal.const";
import { REQ_RES_CODES } from "@/shared/http/enums/request-response-codes.enum";
import store from "@/store";
import { ConfirmForgotPasswordDto } from "@/store/auth/dtos/ConfirmForgotPassword.dto";
import { ForgotPasswordDto } from "@/store/auth/dtos/ForgotPassword.dto";
import { GetAccountDto } from "@/store/auth/dtos/GetAccount.dto";
import { ResendValidateEmailDto } from "@/store/auth/dtos/ResendValidateEmail.dto";
import { ValidateEmailDto } from "@/store/auth/dtos/ValidateEmail.dto";
import {
  ConfirmForgotPassword,
  ForgotPassword,
  GetAccount,
  GetUser,
  Login,
  ResendValidateEmail,
  ValidateEmail,
} from "@/store/auth/services/Login.service";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { LoginDto } from "../store/auth/dtos/Login.dto";
import { useToast } from "./useToastService";

export function useAuthServices() {
  const { t } = useI18n();

  const login = async (login: LoginDto) => {
    await Login(login)
      .then(({ data }) => {
        localStorage.setItem(
          `${window.location.hostname.split(".")[0]}SmartTalentAccessToken`,
          data.accessToken
        );
        store.commit("auth/setToken", data.accessToken);
        router.push({ name: "home" });
      })
      .catch(({ response }) => {
        if (response.messageCode === REQ_RES_CODES.CREDENTIALS_UNKNOWN) {
          useToast().errorToast(t("login.errors.credentialsUnknown"));
        } else if (response.messageCode === REQ_RES_CODES.ENTITY_NOT_FOUND) {
          useToast().errorToast(t("login.errors.userNotFound"));
        } else {
          useToast().errorToast(t("global.unknownError"));
        }
      });
  };

  const logout = () => {
    localStorage.removeItem(
      `${window.location.hostname.split(".")[0]}SmartTalentAccessToken`
    );
    store.commit("auth/setToken", null);
    router.push({ name: "home" });
  };

  const getAccount = (payload: GetAccountDto) => {
    let url = payload.url
      .replace("www.", "")
      .replace("https://", "")
      .replace("http://", "");
    if (url === "localhost") {
      url = AccountUrlLocal;
    }
    GetAccount({ url })
      .then(({ data }) => {
        store.commit("auth/setAccount", data);
      })
      .catch(() => {
        useToast().errorToast(t("accounts.errors.gettingAccount"));
      });
  };

  const getUser = () => {
    GetUser()
      .then(({ data }) => {
        store.commit("auth/setUser", data);
      })
      .catch(({ response }) => {
        if (response.messageCode == !REQ_RES_CODES.ACCESS_EXPIRED) {
          useToast().errorToast(t("users.errors.unknownError"));
        }
      });
  };

  const validateEmail = (
    validationData: ValidateEmailDto,
    goToLogin?: () => void
  ) => {
    ValidateEmail(validationData)
      .then(() => {
        useToast().successToast(t("login.validationSuccess"));
        if (goToLogin) {
          goToLogin();
        }
      })
      .catch(({ response }) => {
        if (response.messageCode === REQ_RES_CODES.CREDENTIALS_UNKNOWN) {
          useToast().errorToast(t("login.errors.incorrectValidationData"));
        } else if (response.messageCode === REQ_RES_CODES.EXPIRED_CODE) {
          useToast().errorToast(t("login.errors.expiredCode"));
        } else if (response.messageCode === REQ_RES_CODES.NOT_AUTHORIZED) {
          useToast().infoToast(t("login.errors.isValidatedYet"));
          if (goToLogin) {
            goToLogin();
          }
        } else {
          useToast().errorToast(t("global.unknownError"));
        }
      });
  };

  const resendValidateEmail = async (
    payload: ResendValidateEmailDto
  ): Promise<boolean> => {
    return await ResendValidateEmail(payload)
      .then(() => {
        useToast().successToast(t("login.resendValidationSuccess"));
        return true;
      })
      .catch(({ response }) => {
        if (response.messageCode === REQ_RES_CODES.CREDENTIALS_UNKNOWN) {
          useToast().errorToast(t("login.errors.incorrectValidateEmailData"));
        } else if (
          response.messageCode === REQ_RES_CODES.ATTEMPTS_LIMIT_EXCEEDED
        ) {
          useToast().errorToast(t("login.errors.attemptsLimitExceeded"));
        } else {
          useToast().errorToast(t("global.unknownError"));
        }
        return false;
      });
  };

  const forgotPassword = async (forgotPassData: ForgotPasswordDto) => {
    return await ForgotPassword(forgotPassData)
      .then(() => {
        useToast().successToast(t("login.forgotPassSuccess"));
        return true;
      })
      .catch(({ response }) => {
        if (response.messageCode === REQ_RES_CODES.CREDENTIALS_UNKNOWN) {
          useToast().errorToast(t("login.errors.incorrectForgotPassData"));
        } else if (
          response.messageCode === REQ_RES_CODES.ATTEMPTS_LIMIT_EXCEEDED
        ) {
          useToast().errorToast(t("login.errors.attemptsLimitExceeded"));
        } else {
          useToast().errorToast(t("global.unknownError"));
        }
        return false;
      });
  };

  const confirmForgotPassword = async (
    confirmForgotPassData: ConfirmForgotPasswordDto,
    goToLogin?: () => void
  ) => {
    confirmForgotPassData.confirmationCode =
      confirmForgotPassData.confirmationCode.toString();
    await ConfirmForgotPassword(confirmForgotPassData)
      .then(() => {
        useToast().successToast(t("login.validationSuccess"));
        if (goToLogin) {
          goToLogin();
        }
      })
      .catch(({ response }) => {
        if (response.messageCode === REQ_RES_CODES.INVALID_CODE) {
          useToast().errorToast(t("login.errors.incorrectValidationData"));
        } else if (response.messageCode === REQ_RES_CODES.CREDENTIALS_UNKNOWN) {
          useToast().errorToast(t("login.errors.incorrectValidationData"));
        } else {
          useToast().errorToast(t("global.unknownError"));
        }
      });
  };

  return {
    login,
    getAccount,
    getUser,
    validateEmail,
    forgotPassword,
    confirmForgotPassword,
    resendValidateEmail,
    logout,
  };
}
