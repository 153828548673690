import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid home h-100" }
const _hoisted_2 = { class: "row mb-3" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "container-fluid p-0" }
const _hoisted_5 = { class: "row justify-content-between" }
const _hoisted_6 = { class: "col-auto d-flex align-items-center" }
const _hoisted_7 = { class: "m-0 pl-2" }
const _hoisted_8 = { class: "col-auto" }
const _hoisted_9 = { class: "container-fluid" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-auto p-0 displayedDataButtonContainer" }
const _hoisted_12 = { class: "col-auto p-0 displayedDataButtonContainer" }
const _hoisted_13 = { class: "col-auto addNewContainer" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-12" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-12" }
const _hoisted_18 = { class: "container-fluid" }
const _hoisted_19 = {
  key: 0,
  class: "row"
}
const _hoisted_20 = { class: "col-12 p-0" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "col-12" }

import CustomFields from "@/components/customFields/CustomFields.vue";
import GeneralCard from "@/components/globals/cards/GeneralCard.vue";
import BasicInput from "@/components/globals/inputs/BasicInput.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import CreateOrUpdateUser from "@/components/users/forms/CreateOrUpdateUser.vue";
import { UsersHeader } from "@/components/users/headers/UsersHeader.headers";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useUsersServices } from "@/composables/useUsersServices";
import { User } from "@/store/auth/models/User.model";
import { Offcanvas } from "bootstrap";
import { computed, onMounted, ref, Ref, watch } from "vue";
import { useI18n } from "vue-i18n";

type formsName = "createUser" | "updateUser" | "customField";


export default /*@__PURE__*/_defineComponent({
  __name: 'TalentManager',
  setup(__props) {

const { t } = useI18n();
const { enableUser, disableUser, deleteUser } = useUsersServices();
const { getModuleCustomFields, customFields } = useCustomFieldsServices();

const dataDisplayMode: Ref<"list" | "cards"> = ref("list");
const searchText: Ref<string> = ref("");
const formName: Ref<"createUser" | "updateUser" | "customField"> = ref();
const confirmModalTitleTranslate: Ref<string> = ref("");
const action: Ref<"edit" | "disable" | "enable" | "delete"> = ref();
const userTemp: Ref<User | null> = ref();
const offCanvasRef = ref();
const confirmationModalRef = ref();
const lastRowIndex: Ref<number> = ref();
const usersHeaderTable = ref();
const tableFilters = ref([]);
const userTable = ref();

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    customFields,
    () => {
      usersHeaderTable.value = UsersHeader(
        optionEdit,
        optionDelete,
        optionDisable,
        optionEnable,
        customFields.value
      );
    },
    { immediate: true, deep: true }
  );
});

const confirmationQuestion = computed(() => {
  return {
    value: `users.${question.value}`,
    params: {
      name: userTemp.value?.name || "",
    },
    needsTranslate: true,
  };
});
const question = computed(() => {
  if (action.value === "disable") {
    return "questionDisable";
  } else if (action.value === "enable") {
    return "questionEnable";
  } else if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

function optionEdit(user: User, index: number) {
  openOffcanvasForm("updateUser");
  action.value = "edit";
  userTemp.value = { ...user };
  lastRowIndex.value = index;
}

function optionDisable(user: User, index: number) {
  action.value = "disable";
  confirmModalTitleTranslate.value = t("global.warnings.status");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionEnable(user: User, index: number) {
  action.value = "enable";
  confirmModalTitleTranslate.value = t("users.warnings.status");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionDelete(user: User, index: number) {
  action.value = "delete";
  confirmModalTitleTranslate.value = t("global.warningDelete");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function handleUserSubmit(data) {
  const { user, isCreating } = data;
  if (isCreating) {
    userTable.value.currentData = [user, ...userTable.value.currentData];
  } else {
    userTable.value.currentData[lastRowIndex.value] = user;
  }
  closeOffcanvasForm();
}

function handleCustomFieldSubmit() {
  closeOffcanvasForm();
}

function openOffcanvasForm(newFormName: formsName) {
  userTemp.value = null;
  formName.value = newFormName;
  const offcanvasInstance = Offcanvas.getOrCreateInstance(
    offCanvasRef.value.$el
  );
  offcanvasInstance.show();
}

function closeOffcanvasForm() {
  const offcanvasInstance = Offcanvas.getOrCreateInstance(
    offCanvasRef.value.$el
  );
  offcanvasInstance.hide();
}

function closedUserForm() {
  console.log("Cerrado");
}

async function handleConfirm() {
  let user = null;
  if (["enable", "disable"].includes(action.value)) {
    if (action.value === "enable") {
      user = await enableUser(userTemp.value);
    } else {
      user = await disableUser(userTemp.value);
    }
    userTable.value.currentData[lastRowIndex.value] = {
      ...userTemp.value,
      status: { id: user.status.id },
    };
  } else if (action.value === "delete") {
    const userDeleted = await deleteUser(userTemp.value);
    if (userDeleted) {
      userTable.value.currentData = userTable.value.currentData.filter(
        (user: User, index) => {
          return index !== lastRowIndex.value;
        }
      );
    }
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(GeneralCard, null, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.$t("home.talentManager.requests")), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("button", {
                          class: _normalizeClass(`btn leftButton ${
                            dataDisplayMode.value === 'list' ? 'activeButton' : ''
                          }`),
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (dataDisplayMode.value = 'list'))
                        }, _cache[5] || (_cache[5] = [
                          _createElementVNode("i", { class: "bi bi-list-task" }, null, -1)
                        ]), 2)
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("button", {
                          class: _normalizeClass(`btn rightButton ${
                            dataDisplayMode.value === 'cards' ? 'activeButton' : ''
                          }`),
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (dataDisplayMode.value = 'cards'))
                        }, _cache[6] || (_cache[6] = [
                          _createElementVNode("i", { class: "bi bi-columns-gap" }, null, -1)
                        ]), 2)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "btn ml-1 btn-gear",
                          onClick: _cache[2] || (_cache[2] = () => openOffcanvasForm('customField'))
                        }, _cache[7] || (_cache[7] = [
                          _createElementVNode("i", { class: "bi bi-gear" }, null, -1)
                        ])),
                        _createElementVNode("button", {
                          type: "button",
                          class: "btn ml-1 btn-gear",
                          onClick: _cache[3] || (_cache[3] = () => openOffcanvasForm('createUser'))
                        }, _toDisplayString(_ctx.$t("home.talentManager.addNew")), 1)
                      ])
                    ])
                  ])
                ])
              ]),
              _cache[8] || (_cache[8] = _createElementVNode("hr", null, null, -1)),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(BasicInput, {
                    name: "searchTalent",
                    placeholder: _ctx.$t('global.search'),
                    modelValue: searchText.value,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((searchText).value = $event))
                  }, null, 8, ["placeholder", "modelValue"])
                ])
              ])
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          (dataDisplayMode.value === 'list')
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(GeneralCard, { class: "mb-3" }, {
                    content: _withCtx(() => [
                      (usersHeaderTable.value)
                        ? (_openBlock(), _createBlock(GeneralTable, {
                            key: 0,
                            dataSource: "/users/",
                            filters: tableFilters.value,
                            headers: usersHeaderTable.value,
                            name: "usersTable",
                            label: _ctx.$t('global.tables.users.label'),
                            ref_key: "userTable",
                            ref: userTable
                          }, null, 8, ["filters", "headers", "label"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createVNode(GeneralOffcanvas, {
          canvasId: "userCanvas",
          canvasName: 
            formName.value === 'customField'
              ? _ctx.$t('customFields.title')
              : formName.value === 'createUser'
              ? _ctx.$t('users.form.create')
              : _ctx.$t('users.form.edit')
          ,
          onClosed: closedUserForm,
          ref_key: "offCanvasRef",
          ref: offCanvasRef
        }, {
          content: _withCtx(() => [
            (['createUser', 'updateUser'].includes(formName.value))
              ? (_openBlock(), _createBlock(CreateOrUpdateUser, {
                  key: 0,
                  ref: "form",
                  "is-creating": true,
                  "initial-values": userTemp.value,
                  onHandleSubmit: handleUserSubmit
                }, null, 8, ["initial-values"]))
              : (formName.value === 'customField')
                ? (_openBlock(), _createBlock(CustomFields, {
                    key: 1,
                    ref: "form",
                    onCustomFieldSubmitted: handleCustomFieldSubmit
                  }, null, 512))
                : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["canvasName"]),
        _createVNode(ConfirmModal, {
          title: confirmModalTitleTranslate.value,
          ref_key: "confirmationModalRef",
          ref: confirmationModalRef,
          message: confirmationQuestion.value,
          confirmAction: handleConfirm
        }, null, 8, ["title", "message"])
      ])
    ])
  ]))
}
}

})