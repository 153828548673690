<template>
  <div class="accordion" id="accordionCustomFields">
    <div class="accordion-item">
      <div
        class="accordion-header createCustomField"
        data-bs-toggle="collapse"
        data-bs-target="#createCustomFieldsAcordion"
        aria-expanded="true"
        aria-controls="createCustomFieldsAcordion"
      >
        <span class="w-100 d-flex justify-content-center">
          Crear Campo Personalizado
        </span>
      </div>
      <div
        id="createCustomFieldsAcordion"
        class="accordion-collapse collapse"
        data-bs-parent="#accordionCustomFields"
      >
        <div class="accordion-body">
          <CreateOrUpdateCustomFields
            ref="createForm"
            :isCreating="true"
            @custom-field-submitted="handleCustomFieldSubmit"
          ></CreateOrUpdateCustomFields>
        </div>
      </div>
    </div>
    <div
      class="accordion-item pl-2"
      v-for="(customField, index) in customFields"
      :key="customField.id"
      ref="acordions"
    >
      <div
        class="accordion-header"
        :id="`showCustomFieldsAcordion${customField.id}`"
      >
        <div class="m-2">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div>
              <span>
                {{ customField.name }}
              </span>
            </div>
            <div>
              <ButtonField
                :button-field="editButton()"
                data-bs-toggle="collapse"
                :data-bs-target="`#showCustomFields${customField.id}`"
                aria-expanded="true"
                :aria-controls="`showCustomFields${customField.id}`"
              ></ButtonField>
              <ButtonField
                :button-field="
                  deleteButton(() => openDeleteCustomFieldModal(index))
                "
              ></ButtonField>
            </div>
          </div>
        </div>
      </div>
      <div
        :id="`showCustomFields${customField.id}`"
        class="accordion-collapse collapse"
        ref="customFieldsRef"
        data-bs-parent="#accordionCustomFields"
      >
        <div class="accordion-body pt-0">
          <CreateOrUpdateCustomFields
            :initial-values="{ customField }"
            :isCreating="false"
            @custom-field-submitted="handleCustomFieldSubmit"
          ></CreateOrUpdateCustomFields>
        </div>
      </div>
      <div>
        <ConfirmModal
          :title="$t('global.warningDelete')"
          ref="modals"
          :message="messageDeletion(customField)"
          :confirmAction="
            async () => {
              await handleDeleteCustomField(customField.id);
            }
          "
        ></ConfirmModal>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CreateOrUpdateCustomFields from "@/components/customFields/CreateOrUpdateCustomFields.vue";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { defineEmits, defineExpose, onMounted, ref } from "vue";
import ButtonField from "../globals/buttons/ButtonField.vue";
import ConfirmModal from "../globals/modals/ConfirmModal.vue";
import { deleteButton } from "./buttons/templates/DeleteButton.template";
import { editButton } from "./buttons/templates/EditButton.template";

const { getModuleCustomFields, customFields, deleteCustomField } =
  useCustomFieldsServices();

const emit = defineEmits(["customFieldSubmitted"]);

const createForm = ref();
const customFieldsRef = ref();
const modals = ref([]);
const isDeleting = ref(false);

const messageDeletion = (customField): LabelType => {
  return {
    value: "customFields.questionDelete",
    params: {
      name: customField.name,
    },
    needsTranslate: true,
  };
};

function resetForms() {
  createForm.value.resetForm();
}

function openDeleteCustomFieldModal(modalIndex) {
  modals.value[modalIndex].openConfirmationModal();
}

async function handleDeleteCustomField(customFieldId) {
  isDeleting.value = true;
  await deleteCustomField(customFieldId);
  isDeleting.value = false;
}

function handleCustomFieldSubmit() {
  emit("customFieldSubmitted");
}

onMounted(async () => {
  await getModuleCustomFields();
});

defineExpose({
  resetForms,
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}

.createCustomField {
  background-color: #7451c2;
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
}

.accordion-item:has(.collapse.show) {
  span {
    display: none;
  }
}
</style>
