import { mapFromHeader } from "@/shared/globals/tables/helpers/tables.helper";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { GetTableData } from "@/shared/globals/tables/services/Tables.services";
import { set } from "lodash";
import { computed, ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
export function useTablesServices(
  urlData: string,
  headers: Ref<TableHeader[]>,
  orderBy: Ref<string>,
  orderType: Ref<"asc" | "desc">
) {
  const currentData = ref([]);
  const currentPage = ref(1);
  const pageSize = ref(5);
  const filters = ref({});
  const totalPages = ref(0);
  const totalItems = ref(0);
  const loadingTable = ref(false);
  const { t } = useI18n();

  const translatedHeaders = computed(() => {
    return headers.value.map((header) => ({
      ...header,
      value: header.value.needsTranslate
        ? t(header.value.value)
        : header.value.value,
    }));
  });

  const mappedData = computed(() => {
    return mapFromHeader(headers.value, currentData.value);
  });

  async function getTableData() {
    loadingTable.value = true;
    try {
      const params: any = {
        page: currentPage.value,
        size: pageSize.value,
        orderBy: orderBy.value,
        orderType: orderType.value,
      };
      if (Object.keys(filters.value).length > 0) {
        params.filters = filters.value;
      }
      const response = await GetTableData(urlData, params);

      currentData.value = response.data.elements;
      totalPages.value = response.data.metadata.totalPages;
      totalItems.value = response.data.metadata.totalItems;
    } catch (error) {
      console.error("Error al obtener los datos de la tabla:", error);
    } finally {
      loadingTable.value = false;
    }
  }

  function applyFilters(filter: { path: string; value: any }) {
    set(filters.value, filter.path, filter.value);
    currentPage.value = 1;
    getTableData();
  }
  return {
    getTableData,
    currentData,
    mappedData,
    translatedHeaders,
    currentPage,
    pageSize,
    totalPages,
    totalItems,
    loadingTable,
    filters,
    applyFilters,
  };
}
