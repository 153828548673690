import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row p-0" }
const _hoisted_2 = { class: "col-12 p-0" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = { class: "col-auto pr-0" }
const _hoisted_5 = { class: "col-auto collapse collapse-horizontal pl-0" }
const _hoisted_6 = { class: "container userDataContainer" }
const _hoisted_7 = { class: "row justify-content-start userName" }
const _hoisted_8 = { class: "col-auto p-0" }
const _hoisted_9 = { class: "row justify-content-center" }
const _hoisted_10 = { class: "col-10 p-0" }
const _hoisted_11 = { class: "container-fluid" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "col-2 iconCol pr-0" }
const _hoisted_14 = { class: "col nameCol collapse collapse-horizontal" }
const _hoisted_15 = { class: "m-0 collapse collapse-horizontal fs-1em" }
const _hoisted_16 = { class: "row align-items-end" }
const _hoisted_17 = { class: "col-12" }
const _hoisted_18 = { class: "container" }
const _hoisted_19 = { class: "row justify-content-center pb-3 extraFunctionsSidebar" }
const _hoisted_20 = { class: "col-10 p-0" }
const _hoisted_21 = { class: "container-fluid p-0" }
const _hoisted_22 = {
  class: /*@__PURE__*/_normalizeClass(`row borderRadiusDefault justify-content-around`)
}
const _hoisted_23 = { class: "col-auto d-flex align-items-center collapse collapse-horizontal" }
const _hoisted_24 = { class: "col form-check form-switch d-flex justify-content-center align-items-center" }
const _hoisted_25 = { class: "col-10 p-0" }
const _hoisted_26 = { class: "container-fluid p-0" }
const _hoisted_27 = { class: "col-auto d-flex align-items-center collapse collapse-horizontal" }

import ProfilePic from "@/components/globals/profile/ProfilePicture.vue";
import { useAuthServices } from "@/composables/useAuthServices";
import { useUsersServices } from "@/composables/useUsersServices";
import { SidebarElements } from "@/shared/dashboard/sidebar/const/SidebarElements.const";
import { RouterActionMenu } from "@/shared/dashboard/sidebar/types/ActionsSidebarElements.type";
import { Collapse } from "bootstrap";
import { computed, onMounted, Ref, ref, watch } from "vue";

import { useRoute, useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeSidebar',
  emits: ["darkModeToggle"],
  setup(__props, { emit: __emit }) {

const route = useRoute();
const router = useRouter();

const { user } = useUsersServices();
const { logout } = useAuthServices();
const elements = ref(SidebarElements);
const sidebarOpened = ref(true);
const collapses: Ref<Collapse[]> = ref([]);

const sidebarClass = computed(() => {
  return sidebarOpened.value ? "open" : "close";
});

const emits = __emit;

const darkMode = ref(false);

watch(sidebarOpened, (newValue) => {
  collapses.value.forEach((collapsable) => {
    if (newValue) {
      collapsable.show();
    } else {
      collapsable.hide();
    }
  });
});

watch(darkMode, () => {
  emits("darkModeToggle", darkMode);
});

function navigate(routeSidebar: RouterActionMenu) {
  router.push(routeSidebar.route);
}

const actualParent = computed(() => {
  return route?.matched[1]?.name;
});

onMounted(() => {
  const arrayElements = Array.from(document.getElementsByClassName("collapse"));
  arrayElements.forEach((element: Element) => {
    const collapse = new Collapse(element, {
      toggle: false,
    });
    collapse.show();
    collapses.value.push(collapse);
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "sidebar",
    class: _normalizeClass(`container-fluid ${sidebarClass.value} pt-2 d-grid`)
  }, [
    _createElementVNode("button", {
      class: "btn bgTransparent",
      id: "collapsableButton",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (sidebarOpened.value = !sidebarOpened.value))
    }, _cache[3] || (_cache[3] = [
      _createElementVNode("i", { class: "bi bi-caret-right" }, null, -1)
    ])),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[6] || (_cache[6] = _createElementVNode("div", {
            class: "row justify-content-center pb-2 pt-2 align-self-start",
            id: "sidebarHeader"
          }, [
            _createElementVNode("div", { class: "col-auto" }, [
              _createElementVNode("img", {
                src: "images/logos/mainLogoShort.png",
                alt: "logo"
              })
            ])
          ], -1)),
          _cache[7] || (_cache[7] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("div", {
            class: _normalizeClass(`row pt-3 pb-3 ${
              !sidebarOpened.value
                ? 'justify-content-center'
                : 'justify-content-around'
            }`),
            id: "sidebarUserCard"
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(ProfilePic, {
                name: `${_unref(user)?.employee.firstName} ${_unref(user)?.employee.lastName}`,
                image: _unref(user)?.profilePic
              }, null, 8, ["name", "image"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("b", null, _toDisplayString(_unref(user)?.employee.firstName), 1)
                  ])
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "row justify-content-start userPosition collapse collapse-horizontal" }, [
                  _createElementVNode("div", { class: "col-auto p-0" }, "aquí ira la posicion")
                ], -1))
              ])
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col-auto d-flex align-items-center collapse collapse-horizontal" }, [
              _createElementVNode("i", { class: "bi bi-gear collapse collapse-horizontal" })
            ], -1))
          ], 2),
          _cache[8] || (_cache[8] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(elements.value, (element, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(`row sidebarElement borderRadiusDefault ${
                    !sidebarOpened.value ? 'justify-content-center' : ''
                  } ${
                    actualParent.value === element.action.metadata.name
                      ? 'active'
                      : ''
                  }`),
                    key: 'sidebarElement' + index,
                    onClick: ($event: any) => (navigate(element.action))
                  }, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("i", {
                        class: _normalizeClass(element.icon)
                      }, null, 2)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t(element.i18nName)), 1)
                    ])
                  ], 10, _hoisted_12))
                }), 128))
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "col-auto pl-3 pr-0 collapse collapse-horizontal" }, [
                    _createElementVNode("i", { class: "bi bi-moon" })
                  ], -1)),
                  _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t("home.navbar.darkMode")), 1),
                  _createElementVNode("div", _hoisted_24, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      role: "switch",
                      "aria-checked": "false",
                      id: "flexSwitchDarkMode",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((darkMode).value = $event))
                    }, null, 512), [
                      [_vModelCheckbox, darkMode.value]
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "row justify-content-center pb-3 extraFunctionsSidebar",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(logout)()))
          }, [
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", {
                  class: _normalizeClass(`row borderRadiusDefault ${
                    sidebarOpened.value
                      ? 'justify-content-start'
                      : 'justify-content-center'
                  }`)
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(`col-auto pl-3 ${sidebarOpened.value ? 'pr-0' : 'p-0'}`)
                  }, _cache[10] || (_cache[10] = [
                    _createElementVNode("i", { class: "bi bi-door-open" }, null, -1)
                  ]), 2),
                  _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t("home.navbar.exit")), 1)
                ], 2)
              ])
            ])
          ])
        ])
      ])
    ])
  ], 2))
}
}

})