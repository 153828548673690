<template>
  <div
    :class="`container-fluid bgLight h-100vh ${
      darkMode == true ? 'darkMode' : ''
    }`"
  >
    <div class="row h-100">
      <div class="col-auto pl-0">
        <HomeSidebar @darkModeToggle="setDarkMode($event)"></HomeSidebar>
      </div>
      <div class="col h-100">
        <div class="container-fluid p-0">
          <div class="row p-0">
            <div class="col" id="headerContainer">
              <HeaderViews :search-bar="true"></HeaderViews>
            </div>
          </div>
          <div class="row p-0">
            <div class="col p-0" id="contentsViewer">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import HeaderViews from "@/components/globals/header/HeaderViews.vue";
import HomeSidebar from "@/components/home/HomeSidebar.vue";
import { useAuthServices } from "@/composables/useAuthServices";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { onBeforeMount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const { setDefaultLang } = useLanguageServices();
const { getUser } = useAuthServices();
const router = useRouter();

const darkMode = ref(false);

function setDarkMode(event) {
  darkMode.value = event._value;
}

onBeforeMount(() => {
  if (
    localStorage.getItem(
      `${window.location.hostname.split(".")[0]}SmartTalentAccessToken`
    )
  ) {
    getUser();
  }
  setDefaultLang();
});

onMounted(() => {
  document.getElementById("sidebarCollapse")?.addEventListener("click", () => {
    document.getElementById("sidebar")?.classList.toggle("active");
  });
  router.push("/dashboard");
});
</script>

<style scoped lang="scss">
$headerHeight: 4.5em;

#contentsViewer {
  overflow-y: auto;
  height: calc(99vh - $headerHeight);
}
#headerContainer {
  height: $headerHeight;
}
</style>
