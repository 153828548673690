import { useLanguageServices } from "@/composables/useLanguageServices";
import { useValidations } from "@/composables/useValidations";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  passwordValidation,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { CatalogClassInterface } from "@/store/catalogs/interfaces/CatalogClass.interface";
import { CatalogClass } from "@/store/catalogs/models/CatalogClass.model";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";

export const CreateOrUpdateUserTemplate = (
  action,
  isCreating: boolean,
  catalogs: CatalogClassInterface[],
  customFields: CustomFieldInterface[]
): Form => {
  const catalog = catalogs?.find((c) => c.key === "USERS_STATUS_CLASS");
  const { lang } = useLanguageServices();
  const statuses = catalog?.catalogs.map((catalog) => {
    return {
      name: {
        value: catalog?.catalogMetadata?.language?.[lang.value] ?? catalog.name,
        needsTranslate: false,
      },
      value: catalog.name,
    };
  });
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "user.firstName",
            inputName: "userfirstName",
            label: {
              value: "users.form.name",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              const validation = [stringYup, requiredYup];
              return validation;
            },
          },
          {
            path: "user.lastName",
            inputName: "userlastName",
            label: {
              value: "users.form.surname",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              const validation = [stringYup, requiredYup];
              return validation;
            },
          },
          {
            path: "user.dni",
            inputName: "userDni",
            label: {
              value: "users.form.dni",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              const validation = [stringYup, requiredYup];
              return validation;
            },
          },
          {
            path: "user.email",
            inputName: "userEmail",
            label: {
              value: "users.form.email",
              needsTranslate: true,
            },
            type: "email",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              const validation = [stringYup, requiredYup];
              return validation;
            },
          },
          {
            path: "user.birthDate",
            inputName: "userBirthDate",
            label: {
              value: "users.form.birthDate",
              needsTranslate: true,
            },
            type: "date",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              const validation = [stringYup, requiredYup];
              return validation;
            },
          },
          {
            path: "user.hireDate",
            inputName: "userHireDate",
            label: {
              value: "users.form.hireDate",
              needsTranslate: true,
            },
            type: "date",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              const validation = [stringYup, requiredYup];
              return validation;
            },
          },
          {
            path: "user.timezone",
            inputName: "userTimezone",
            label: {
              value: "users.form.timezone",
              needsTranslate: true,
            },
            type: "select",
            options: [
              {
                name: {
                  value: "global.timezones.mx",
                  needsTranslate: true,
                },
                value: "America/Mexico_City",
              },
              {
                name: {
                  value: "global.timezones.ar",
                  needsTranslate: true,
                },
                value: "America/Argentina/Buenos_Aires",
              },
              {
                name: {
                  value: "global.timezones.col",
                  needsTranslate: true,
                },
                value: "America/Bogota",
              },
            ],
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              const validation = [stringYup, requiredYup];
              return validation;
            },
          },
          {
            path: "user.password",
            inputName: "userPassword",
            label: {
              value: "users.form.password",
              needsTranslate: true,
            },
            type: "password",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return passwordValidation;
            },
          },
          {
            path: "user.statusName",
            inputName: "userStatus",
            label: {
              value: "users.form.initialStatus.label",
              needsTranslate: true,
            },
            type: "select",
            options: statuses,
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              const validation = [stringYup, requiredYup];
              return validation;
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating ? "users.form.create" : "users.form.edit",
        needsTranslate: true,
      },
      action,
    },
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(customFields, "user", "user"),
    });
  }
  return form;
};
