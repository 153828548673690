import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = ["placeholder", "id"]



export default /*@__PURE__*/_defineComponent({
  __name: 'BasicInput',
  props: /*@__PURE__*/_mergeModels({
  name: { type: String, required: true },
  placeholder: { type: String, required: false },
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

const props = __props;

const inputValue = _useModel(__props, "modelValue");

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "input-group-append d-flex align-items-center w-0" }, [
      _createElementVNode("button", {
        class: "btn rounded-pill bg-white buttonSearch",
        type: "button"
      }, [
        _createElementVNode("i", { class: "bi bi-search" })
      ])
    ], -1)),
    _withDirectives(_createElementVNode("input", {
      class: "form-control rounded-pill",
      type: "text",
      placeholder: props.placeholder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
      id: `${props.name}Input`
    }, null, 8, _hoisted_2), [
      [_vModelText, inputValue.value]
    ])
  ]))
}
}

})