import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { buttonTranslate } from "./CreateOrUpdateSelectSettings.template";

export const createOrUpdateCustomField = (
  action,
  formMain,
  validations,
  initialValues,
  isCreating,
  actionSettings,
  actionTranslate
): Form => {
  return {
    sections: [
      {
        fields: [
          {
            path: "customField.name",
            inputName: "customFieldName",
            label: {
              value: "customFields.name",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              const { yupRules } = validations;
              const { stringYup, requiredYup } = yupRules;
              const validation = [stringYup, requiredYup];
              return validation;
            },
            buttons: [{ ...buttonTranslate, action: actionTranslate }],
          },
          {
            path: "customField.type",
            inputName: "customFieldType",
            label: {
              value: "customFields.type",
              needsTranslate: true,
            },
            type: "select",
            options: [
              {
                name: {
                  value: "customFields.types.text",
                  needsTranslate: true,
                },
                value: "text",
              },
              {
                name: {
                  value: "customFields.types.number",
                  needsTranslate: true,
                },
                value: "number",
              },
              {
                name: {
                  value: "customFields.types.select",
                  needsTranslate: true,
                },
                value: "select",
              },
              {
                name: {
                  value: "customFields.types.multipleSelect",
                  needsTranslate: true,
                },
                value: "multiple-select",
              },
              {
                name: {
                  value: "customFields.types.date",
                  needsTranslate: true,
                },
                value: "date",
              },
            ],
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              const { yupRules } = validations;
              const { stringYup, requiredYup } = yupRules;
              const validation = [stringYup, requiredYup];
              return validation;
            },
            buttons: [
              {
                conditions(value) {
                  if (["text", "number", "multiple-select"].includes(value)) {
                    return true;
                  }
                  return false;
                },
                backgroundColor: "#7451c2",
                customClass: "ml-1",
                disableCustomClass: "btn disabled",
                graphic: {
                  icon: {
                    icon: "bi bi-gear",
                    color: "white",
                    backgroundColor: "#7451c2",
                  },
                },
                action: actionSettings,
                metadata: {
                  isAppend: false,
                  positionInField: "end",
                },
              },
            ],
            disabled: initialValues ? true : false,
          },
          {
            path: "customField.required",
            inputName: "isCustomFieldRequired",
            label: {
              value: "customFields.required",
              needsTranslate: true,
            },
            type: "checkbox",
            metadata: {
              labelPosition: "right",
              tooltipPosition: "top",
            },
          },
        ],
      },
      {
        conditions: () => {
          return (
            formMain.value?.values?.customField?.type === "select" ||
            formMain.value?.values?.customField?.type === "multiple-select"
          );
        },
        name: { value: "customFields.optionsValues", needsTranslate: true },
        fields: [],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating
          ? "customFields.addField"
          : "customFields.updateField",
        needsTranslate: true,
      },
      action,
    },
  };
};
