import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-10" }
const _hoisted_4 = {
  class: "container",
  id: "formBox"
}
const _hoisted_5 = { class: "row justify-content-center mb-3" }
const _hoisted_6 = { class: "col-auto d-flex align-items-center" }
const _hoisted_7 = { class: "textBlack" }
const _hoisted_8 = { class: "row justify-content-between mb-4" }
const _hoisted_9 = { class: "col-auto" }
const _hoisted_10 = {
  class: "actionableLabel",
  "data-bs-target": "#carouselAuthViews",
  "data-bs-slide-to": "1"
}
const _hoisted_11 = { class: "col-auto" }
const _hoisted_12 = {
  class: "actionableLabel",
  "data-bs-target": "#carouselAuthViews",
  "data-bs-slide-to": "2"
}
const _hoisted_13 = {
  class: "row",
  id: "anotherOptionsDivider"
}
const _hoisted_14 = { class: "col-auto p-0 dividerText d-flex align-items-center" }
const _hoisted_15 = { class: "m-0" }

import { useAuthServices } from "@/composables/useAuthServices";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { LoginUserForm } from "./forms/templates/LoginUser.template";
import { useValidations } from "@/composables/useValidations";
import GeneralForm from "../globals/forms/GeneralForm.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginBox',
  setup(__props) {

const validations = useValidations();
const store = useStore();
const { login } = useAuthServices();

const formTemplate = ref(LoginUserForm(submitLogin, validations));
const formRef = ref();

const accountId = computed(() => {
  return store.state.auth.account?.id;
});

async function submitLogin(): Promise<void> {
  await login({ ...formRef.value.values, account: { id: accountId.value } });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t("login.title")), 1)
            ])
          ]),
          _createVNode(GeneralForm, {
            ref_key: "formRef",
            ref: formRef,
            form: formTemplate.value,
            formName: "loginUser"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("login.forgotPassword")), 1)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("login.validateEmail")), 1)
                ])
              ])
            ]),
            _: 1
          }, 8, ["form"]),
          _createElementVNode("div", _hoisted_13, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col pr-1" }, [
              _createElementVNode("hr")
            ], -1)),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t("login.anotherAccessOptions")), 1)
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col pl-1" }, [
              _createElementVNode("hr")
            ], -1))
          ]),
          _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"row justify-content-around pt-2 pb-3\" id=\"anotherOption\" data-v-68cec50d><div class=\"col-auto\" data-v-68cec50d><button class=\"btn bg-transparent extraOptionButton p-0\" data-v-68cec50d><img class=\"bg-transparent\" src=\"images/icon/brands/icon-apple.svg\" width=\"45px\" alt=\"appleLogo\" data-v-68cec50d></button></div><div class=\"col-auto\" data-v-68cec50d><button class=\"btn bg-transparent extraOptionButton p-0\" data-v-68cec50d><img class=\"bg-transparent\" src=\"images/icon/brands/icon-microsoft.svg\" width=\"45px\" alt=\"microsoftLogo\" data-v-68cec50d></button></div><div class=\"col-auto\" data-v-68cec50d><button class=\"btn bg-transparent extraOptionButton p-0\" data-v-68cec50d><img class=\"bg-transparent\" src=\"images/icon/brands/icon-google.svg\" width=\"45px\" alt=\"googleLogo\" data-v-68cec50d></button></div></div>", 1))
        ])
      ])
    ])
  ]))
}
}

})