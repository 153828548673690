import { Module } from "vuex";
import { CatalogClass } from "./models/CatalogClass.model";
import { CatalogClassInterface } from "./interfaces/CatalogClass.interface";

interface State {
  catalogsClasses: CatalogClass[];
}

export const catalogsStore: Module<State, unknown> = {
  namespaced: true,
  state: {
    catalogsClasses: [],
  },
  getters: {},
  mutations: {
    setCatalogs(state, catalogClass: CatalogClassInterface[]) {
      state.catalogsClasses = catalogClass;
    },
    addCatalog(state, catalogClass: CatalogClassInterface) {
      state.catalogsClasses = [...state.catalogsClasses, catalogClass];
    },
    deleteCatalog(state, catalogClass: CatalogClassInterface) {
      state.catalogsClasses = state.catalogsClasses.filter(
        (c) => c.id === catalogClass.id
      );
    },
  },
  actions: {},
  modules: {},
};
