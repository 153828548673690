<template>
  <div class="container-fluid headerView pb-3 pt-3">
    <div class="row">
      <div class="col-auto">
        <BasicInput
          name="searchHeader"
          :placeholder="$t('global.search')"
          v-model="searchText"
        ></BasicInput>
      </div>
      <div class="col-auto">
        <RoundedIcon
          fontSize="1.2em"
          width="2.1em"
          icon="bi-bell"
          :bordered="true"
        ></RoundedIcon>
      </div>
      <div class="col"></div>
      <div class="col-auto d-flex justify-content-end" id="headerLogo">
        <img src="images/logos/mainLogoLong.png" alt="mainLogoLong" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineProps, ref } from "vue";
import RoundedIcon from "../icons/RoundedIcon.vue";
import BasicInput from "../inputs/BasicInput.vue";
const props = defineProps({
  searchBar: {
    type: Boolean,
    required: false,
  },
});

const searchText = ref("");
</script>
<style scoped lang="scss">
.cardContainer {
  background-color: white;
}

#headerLogo {
  img {
    height: 2em;
  }
}
</style>
