import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-10" }
const _hoisted_4 = {
  class: "container",
  id: "formBox"
}
const _hoisted_5 = { class: "row justify-content-center mb-3" }
const _hoisted_6 = { class: "col-auto d-flex align-items-center" }
const _hoisted_7 = { class: "textBlack" }
const _hoisted_8 = { class: "row justify-content-between" }
const _hoisted_9 = { class: "col-auto" }
const _hoisted_10 = { class: "col-auto" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col p-0" }

import { useAuthServices } from "@/composables/useAuthServices";
import { useValidations } from "@/composables/useValidations";
import { ref } from "vue";
import { forgotPasswordStep1 } from "./forms/templates/ForgotPasswordStep1.template";
import { forgotPasswordStep2 } from "./forms/templates/ForgotPasswordStep2.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ForgotPassword',
  emits: ["goToLogin"],
  setup(__props, { emit: __emit }) {

const { forgotPassword, confirmForgotPassword } = useAuthServices();
const validations = useValidations();

const emit = __emit;
const isFirstStep = ref<boolean>(true);
const formRef = ref();
const formForgotPasswordStep1 = ref(
  forgotPasswordStep1(submitForgotPassword, validations)
);
const formForgotPasswordStep2 = ref(
  forgotPasswordStep2(submitForgotPassword, validations)
);

async function submitForgotPassword(): Promise<void> {
  const { values } = formRef.value;
  const { email, newPassword, confirmationCode } = values;
  if (isFirstStep.value) {
    isFirstStep.value = !(await forgotPassword({ email }));
  } else {
    await confirmForgotPassword(
      { email, newPassword, confirmationCode },
      goToLogin
    );
  }
}

function goToLogin(): void {
  isFirstStep.value = true;
  formRef.value.resetForm({});
  emit("goToLogin");
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t("login.forgotPassTitle")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[2] || (_cache[2] = _createElementVNode("i", { class: "bi bi-arrow-left-circle actionableLabel mr-1" }, null, -1)),
              _createElementVNode("span", {
                class: "actionableLabel",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (goToLogin()))
              }, _toDisplayString(_ctx.$t("login.name")), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", {
                class: "actionableLabel",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (isFirstStep.value = false))
              }, _toDisplayString(_ctx.$t("login.haveACodeYet")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              (isFirstStep.value)
                ? (_openBlock(), _createBlock(GeneralForm, {
                    key: 0,
                    ref_key: "formRef",
                    ref: formRef,
                    form: formForgotPasswordStep1.value,
                    formName: "forgotPassword"
                  }, null, 8, ["form"]))
                : (_openBlock(), _createBlock(GeneralForm, {
                    key: 1,
                    ref_key: "formRef",
                    ref: formRef,
                    form: formForgotPasswordStep2.value,
                    formName: "forgotPassword"
                  }, null, 8, ["form"]))
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})