import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import InputMaster from "@/components/globals/inputs/InputMaster.vue";
import { generateFilterField } from "@/shared/globals/tables/helpers/tables.helper";
import { PropType, computed } from "vue";
import { TableHeader } from "../../../shared/globals/tables/interfaces/TableHeader.interface";


export default /*@__PURE__*/_defineComponent({
  __name: 'ColumnFilter',
  props: {
  columnHeader: { type: Object as PropType<TableHeader>, required: true },
},
  emits: ["confirmValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const inputData = computed(() => {
  return generateFilterField(props.columnHeader);
});

function emitValue(value) {
  emit("confirmValue", {
    value: value.value,
    path: props.columnHeader.isCustomField
      ? `customFields.${props.columnHeader.mappedKey}`
      : props.columnHeader.mappedKey,
  });
}

return (_ctx: any,_cache: any) => {
  return (
      !['catalog', 'action'].includes(__props.columnHeader.columnType) &&
      !__props.columnHeader.isCustomField
    )
    ? (_openBlock(), _createBlock(InputMaster, _mergeProps({
        key: 0,
        onConfirmValue: emitValue
      }, inputData.value, { reachableValue: true }), null, 16))
    : _createCommentVNode("", true)
}
}

})