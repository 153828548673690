<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-10">
        <div class="container" id="formBox">
          <div class="row justify-content-center mb-3">
            <div class="col-auto d-flex align-items-center">
              <h2 class="textBlack">{{ $t("login.title") }}</h2>
            </div>
          </div>
          <GeneralForm ref="formRef" :form="formTemplate" formName="loginUser">
            <div class="row justify-content-between mb-4">
              <div class="col-auto">
                <span
                  class="actionableLabel"
                  data-bs-target="#carouselAuthViews"
                  data-bs-slide-to="1"
                  >{{ $t("login.forgotPassword") }}</span
                >
              </div>
              <div class="col-auto">
                <span
                  class="actionableLabel"
                  data-bs-target="#carouselAuthViews"
                  data-bs-slide-to="2"
                  >{{ $t("login.validateEmail") }}</span
                >
              </div>
            </div>
          </GeneralForm>
          <div class="row" id="anotherOptionsDivider">
            <div class="col pr-1"><hr /></div>
            <div class="col-auto p-0 dividerText d-flex align-items-center">
              <p class="m-0">{{ $t("login.anotherAccessOptions") }}</p>
            </div>
            <div class="col pl-1"><hr /></div>
          </div>
          <div class="row justify-content-around pt-2 pb-3" id="anotherOption">
            <div class="col-auto">
              <button class="btn bg-transparent extraOptionButton p-0">
                <img
                  class="bg-transparent"
                  src="images/icon/brands/icon-apple.svg"
                  width="45px"
                  alt="appleLogo"
                />
              </button>
            </div>
            <div class="col-auto">
              <button class="btn bg-transparent extraOptionButton p-0">
                <img
                  class="bg-transparent"
                  src="images/icon/brands/icon-microsoft.svg"
                  width="45px"
                  alt="microsoftLogo"
                />
              </button>
            </div>
            <div class="col-auto">
              <button class="btn bg-transparent extraOptionButton p-0">
                <img
                  class="bg-transparent"
                  src="images/icon/brands/icon-google.svg"
                  width="45px"
                  alt="googleLogo"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuthServices } from "@/composables/useAuthServices";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { LoginUserForm } from "./forms/templates/LoginUser.template";
import { useValidations } from "@/composables/useValidations";
import GeneralForm from "../globals/forms/GeneralForm.vue";

const validations = useValidations();
const store = useStore();
const { login } = useAuthServices();

const formTemplate = ref(LoginUserForm(submitLogin, validations));
const formRef = ref();

const accountId = computed(() => {
  return store.state.auth.account?.id;
});

async function submitLogin(): Promise<void> {
  await login({ ...formRef.value.values, account: { id: accountId.value } });
}
</script>

<style lang="scss" scoped>
.input-group {
  $labelWith: 35%;
  $inputWith: calc(100% - $labelWith);
  span {
    width: $labelWith;
    text-align: start;
  }
  input {
    width: $inputWith;
  }
}

#anotherOptionsDivider {
  hr {
    color: rgb(71, 71, 71);
  }
  font-size: 0.6em;
  color: rgb(0, 0, 0);
}

#anotherOption {
  button {
    &:hover {
      background-color: $FourthColor;
    }
  }
}

.extraOptionButton {
  &:hover {
    background-color: rgb(206, 206, 206) !important;
  }
}
</style>
