<template>
  <div class="container-fluid cardContainer borderRadiusDefault pt-2 pb-2">
    <div class="row">
      <div class="col-12">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped lang="scss">
.cardContainer {
  background-color: white;
}

.darkMode {
  .cardContainer {
    background-color: $GrayLightDark;
  }
}
</style>
