import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";

export const editButton = (): ButtonTemplate => {
  return {
    backgroundColor: "#7451c2",
    customClass: "btn ml-1",
    graphic: {
      icon: {
        icon: "bi bi-pencil-square",
        color: "white",
        backgroundColor: "#7451c2",
      },
    },
  };
};
