<template>
  <div id="authContainer" class="container-fluid m-0 w-100">
    <div class="row justify-content-between h-100 w-100vw" id="loginContainer">
      <div class="col-lg-5 col-7 d-flex p-0" id="loginBoxContainer">
        <div class="container-fluid d-grid">
          <div class="row justify-content-end">
            <div class="col-auto">
              <ChangeLang />
            </div>
          </div>
          <div class="row" id="mainLogoContainer">
            <div class="col-auto">
              <img src="images/logos/mainLogoShort.png" alt="" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div id="carouselAuthViews" class="carousel slide">
                <div class="carousel-inner text-light text-center">
                  <div class="carousel-item active">
                    <LoginBox />
                  </div>
                  <div class="carousel-item">
                    <ForgotPassword @go-to-login="goToLogin" />
                  </div>
                  <div class="carousel-item">
                    <ValidateEmail @go-to-login="goToLogin" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row mb-4 align-self-end justify-content-end"
            id="longLogoContainer"
          >
            <div class="col-auto">
              <img src="images/logos/mainLogoLong.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-7 col-5 d-flex p-0 justify-content-center align-content-center"
        id="carouselImages"
      >
        <div id="carouselImagesLogin" class="carousel slide w-100">
          <div class="carousel-inner h-100">
            <div
              v-for="(image, index) in imagesCarousel"
              :key="image"
              :class="`carousel-item w-100 h-100 ${
                index === 0 ? 'active' : ''
              }`"
              :style="{
                backgroundImage: `url('${image}')`,
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ForgotPassword from "@/components/auth/ForgotPassword.vue";
import LoginBox from "@/components/auth/LoginBox.vue";
import ValidateEmail from "@/components/auth/ValidateEmail.vue";
import ChangeLang from "@/components/lang/ChangeLang.vue";
import { useToast } from "@/composables/useToastService";
import { Carousel } from "bootstrap";
import { onBeforeMount, onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const store = useStore();
const { t } = useI18n();

const carouselBoxesInstance: Ref<Carousel | null> = ref(null);

const imagesCarousel = ref([
  "images/login/carousel/login-carousel-image-1.png",
  "images/login/carousel/login-carousel-image-2.jpg",
  "images/login/carousel/login-carousel-image-3.jpg",
  "images/login/carousel/login-carousel-image-4.jpg",
]);

function goToLogin() {
  carouselBoxesInstance.value?.to(0);
}

// lifecycle hooks
onBeforeMount(() => {
  if (store.state.auth.launchExpiredSessionToast) {
    useToast().infoToast(t("users.errors.accessExpired"));
    store.commit("auth/setLaunchExpiredSessionToast", false);
  }
});
onMounted(() => {
  new Carousel("#carouselImagesLogin", {
    interval: 5000,
    keyboard: false,
    ride: true,
  }).cycle();
  carouselBoxesInstance.value = new Carousel("#carouselAuthViews", {
    interval: 99999999999999,
    touch: false,
  });
  carouselBoxesInstance.value.cycle();
  carouselBoxesInstance.value?.to(3);
});
</script>

<style lang="scss" scoped>
::v-deep .label {
  color: black;
}

.carousel-inner {
  overflow: visible !important;
}

#authContainer {
  height: 100vh;
  width: 99vw;
}

#loginContainer {
  align-self: center;
}

#carouselImages {
  overflow: hidden;
}

#carouselImagesLogin {
  .carousel-item {
    transition: transform 1s ease-out;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

#carouselAuthViews {
  .carousel-item {
    transition: transform 0.5s ease-out;
  }
}

#mainLogoContainer {
  img {
    width: 35%;
  }
}

#longLogoContainer {
  img {
    width: 13em;
  }
}
</style>
