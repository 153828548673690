import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";

export const cancelButton = (action): ButtonTemplate => {
  return {
    backgroundColor: "#5c636a",
    text: { value: "global.cancel", needsTranslate: true },
    customClass: "btn w-100",
    action,
  };
};
