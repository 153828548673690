import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "aria-labelledby"]
const _hoisted_2 = { class: "offcanvas-header" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "offcanvas-body" }

import { onMounted, Ref, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralOffcanvas',
  props: {
  canvasId: { type: String, required: true },
  canvasName: { type: String, required: true },
},
  emits: ["closed"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const canvas: Ref<Element> = ref(undefined);

function closeOffcanvas() {
  emit("closed");
}

onMounted(() => {
  canvas.value = document.getElementById(props.canvasId);
  canvas.value.addEventListener("hidden.bs.offcanvas", (event) => {
    emit("closed");
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "offcanvas offcanvas-end",
    tabindex: "-1",
    id: __props.canvasId,
    "aria-labelledby": `${__props.canvasId}Label`
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", {
        class: "offcanvas-title",
        id: `${__props.canvasId}Label`
      }, _toDisplayString(__props.canvasName), 9, _hoisted_3),
      _createElementVNode("button", {
        type: "button",
        class: "btn-close",
        "data-bs-dismiss": "offcanvas",
        "aria-label": "Close",
        onClick: closeOffcanvas
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "content")
    ])
  ], 8, _hoisted_1))
}
}

})