import { REQ_RES_CODES } from "@/shared/http/enums/request-response-codes.enum";
import store from "@/store/index";
import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_SMART_TALENT_CORE_URL,
});
// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    config.headers.set("Access-Control-Allow-Origin", "*");
    const token = localStorage.getItem(
      `${window.location.hostname.split(".")[0]}SmartTalentAccessToken`
    );
    if (token) {
      config.headers.set("authorization", `Bearer ${token}`);
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response: AxiosResponse) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response.data.messageCode === REQ_RES_CODES.ACCESS_EXPIRED) {
      localStorage.removeItem(
        `${window.location.hostname.split(".")[0]}SmartTalentAccessToken`
      );
      store.dispatch("auth/launchExpiredSession");
    }
    return Promise.reject({ ...error, response: error.response.data });
  }
);

export default axiosInstance;
