import { useYupRules } from "@/composables/useYupRules";
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import {
  arrayYup,
  dateYup,
  isEmpty,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { getLang } from "@/shared/locales/services/i18n.services";
import { CustomFieldInterface } from "../interfaces/CustomField.interface";

export function customFieldsToField(
  customFields: CustomFieldInterface[],
  pathPrefix: string,
  inputNamePrefix: string
): Field[] {
  const yupRules = useYupRules();
  const lang = getLang();
  const customFieldsArray: Field[] = customFields.map((customField): Field => {
    const translations = customField?.metadata?.language;

    const complement: Partial<Field> = {};

    if (
      customField.type === "select" ||
      customField.type === "multiple-select"
    ) {
      const options = customField.metadata.options;
      complement["options"] = Object.keys(options).map((optionKey) => {
        const optionsTranslated = translations?.options
          ? translations?.options[lang]
          : null;
        const translation = optionsTranslated
          ? optionsTranslated[optionKey]
          : null;
        return {
          value: optionKey,
          name: {
            value: translation ?? options[optionKey],
            needsTranslate: false,
          },
        };
      });
    }

    if (translations?.name && translations?.name[lang]) {
      complement["label"] = {
        value: translations.name[lang],
        needsTranslate: false,
      };
    }

    return {
      path: `${pathPrefix}.customFields.${customField.id}`,
      inputName: `${inputNamePrefix}.${customField.id}`,
      label: {
        value: customField.name,
        needsTranslate: false,
      },
      type: customField.type,
      metadata: {
        tooltipPosition: "left",
      },
      methodsYup: () => {
        let validations = [];

        if (["text", "select", "number"].includes(customField.type)) {
          validations = [stringYup];
        }
        if (customField.type === "multiple-select") {
          validations = [arrayYup];
        }
        if (customField.type === "date") {
          validations = [dateYup];
        }
        if (customField?.metadata?.fieldSettings?.validations) {
          (
            customField.metadata.fieldSettings.validations as Array<object>
          ).forEach((validation) => {
            const validationRule = validation as {
              value: string;
            };
            if (
              customField?.metadata?.fieldSettings?.params?.[
                validationRule.value
              ]
            ) {
              validations = [
                ...validations,
                yupRules[validationRule.value](
                  Number(
                    customField?.metadata?.fieldSettings?.params?.[
                      validationRule.value
                    ]
                  )
                ),
              ];
            } else {
              validations = [...validations, yupRules[validationRule.value]];
            }
          });
        }
        if (
          customField.required &&
          ["multiple-select", "text", "select", "number", "date"].includes(
            customField.type
          )
        ) {
          validations = [...validations, requiredYup];
        } else {
          validations = [...validations, isEmpty];
        }
        return validations;
      },
      ...complement,
    };
  });
  return customFieldsArray;
}
