export const es = {
  name: "Español",
  global: {
    timezones: {
      mx: "México",
      ar: "Argentina",
      col: "Colombia",
    },
    warningDelete: "Atención de eliminación",
    confirm: "Confirmar",
    cancel: "Cancelar",
    back: "Atrás",
    employee: "Empleado",
    earnings: "Ingresos",
    inputs: {
      ruleName: "Reglas",
      select: "Seleccione una opción",
      rules: {
        required: "Debe tener un valor",
        exactChars: "Debe contener {quantity} caracteres",
        upperCaseLimited: "Debe contener al menos {quantity} letras mayusculas",
        lowerCaseLimited: "Debe contener al menos {quantity} letras minusculas",
        especialCharactersLimited:
          "Debe contener al menos {quantity} caracteres especiales",
        numberCharactersLimited:
          "Debe contener al menos {quantity} caracteres numericos",
        minQuantity: "Debe contener al menos {quantity} caracteres",
        numberCharacters: "Debe estar compuesta de caracteres numericos",
        email: "Debe ser una dirección de correo válida",
      },
    },
    tables: {
      resultsPerPage: "Resultados por página",

      emptyData:
        "Reajusta los filtros, no hay datos que correspondan a estos criterios",
    },
    search: "Buscar",
    unknownError:
      "Ha ocurrido un error inesperado, por favor, contacta a soporte",
    statuses: {
      active: "Activo",
      pending: "Pendiente",
      deny: "Rechazado",
      new: "Nuevo",
      inactive: "Inactivo",
    },
    permissions: {
      jobPosting: "Publicación de Ofertas de Empleo",
      applicationManagement: "Recepción y Gestión de Candidaturas",
      candidateFiltering: "Herramientas de Filtrado y Selección de Candidatos",
      interviewManagement: "Gestión de Entrevistas y Seguimiento de Candidatos",
      jobListingView: "Visualización de Ofertas de Empleo",
      onlineApplication: "Postulación en Línea y Carga de CV",
      onboardingTaskManagement: "Creación y Gestión de Tareas de Onboarding",
      orientationPrograms: "Programas de Orientación y Capacitación Inicial",
      employeeExitProcesses: "Procesos de Salida de Empleados",
      performanceEvaluations: "Evaluaciones de Desempeño Periódicas",
      objectivesManagement: "Gestión de Objetivos y Resultados",
      developmentPlans: "Planes de Desarrollo Individual y Seguimiento",
      payrollAdministration: "Administración de la Nómina",
      benefitsManagement: "Gestión de Beneficios y Recompensas",
      benchmarkingTools: "Herramientas de Análisis y Benchmarking",
      timeTracking: "Control de Horarios y Asistencia",
      shiftManagement: "Gestión de Turnos y Ausencias",
      communicationTools: "Herramientas de Comunicación Interna",
      conflictManagement: "Gestión de Conflictos y Seguimiento de Casos",
      climateSurveys: "Encuestas de Clima Laboral y Seguimiento",
      wellBeingPrograms: "Programas de Bienestar",
      workplaceSafety: "Gestión de la Seguridad en el Trabajo",
      incidentTracking: "Seguimiento de Incidentes",
      contractManagement: "Gestión de Contratos y Documentación Laboral",
      documentFiling: "Archivo y Búsqueda de Documentos",
      kpiDashboards: "Dashboards de KPIs",
      customReports: "Reportes Personalizados y Análisis",
      dataVisualization: "Herramientas de Visualización de Datos",
      postManagement: "Publicaciones y Comentarios",
      postReactions: "Reacciones a Publicaciones",
      forumManagement: "Gestión de Foros de Discusión",
    },
  },
  login: {
    title: "Bienvenido de nuevo!",
    name: "Iniciar sesión",
    email: "Correo electronico",
    password: "Contraseña",
    newPassword: "Nueva contraseña",
    submit: "Ingresa",
    viewsSubmit: "Enviar",
    request: "Solicitar",
    errors: {
      credentialsUnknown:
        "Correo electronico o contraseña incorrectos, intentalo de nuevo.",
      invalidValidationData: "Datos de validación incorrectos",
      incorrectForgotPassData:
        "Este correo no ha sido registrado, intenta con una direccion de correo valida.",
      incorrectValidateEmailData:
        "Este correo no ha sido registrado, intenta con una direccion de correo valida.",
      attemptsLimitExceeded:
        "Lo has intentado muchas veces, intenta de nuevo mas tarde.",

      expiredCode: "El codigo ha expirado, intenta solicitar uno nuevo",
      userNotFound:
        "No existe un usuario registrado con ese correo electronico.",
    },
    validationSuccess: "Validación exitosa",
    resendValidationSuccess:
      "Enviamos un codigo de confirmación a tu correo electronico!",
    forgotPassSuccess:
      "Enviamos un codigo de confirmación a tu correo electronico!",
    confirmForgotPassSuccess:
      "Tu contraseña ha cambiado. ahora puedes iniciar sesión!",
    forgotPassTitle: "Recupera tu contraseña!",
    forgotPassword: "Has olvidado tu contraseña?",
    validateEmailTitle: "Valida tu correo electronico",
    validateEmail: "Deseas validar tu email?",
    resendValidateEmail: "Solicitar nuevo codigo",
    confirmationCode: "Codigo de verificación",
    anotherAccessOptions: "También puedes acceder con",
    haveACodeYet: "Ya tengo un codigo!",
    isValidatedYet: "Este correo ya está validado",
  },
  accounts: {
    errors: {
      gettingAccount:
        "Ha ocurrido un error obteniendo la información de la cuenta, por favor, contacta a soporte.",
    },
  },
  users: {
    title: "Usuarios",
    form: {
      name: "Nombre",
      surname: "Apellido",
      email: "Correo",
      password: "Contraseña",
      dni: "Documento de Identidad",
      initialStatus: {
        label: "Estado Inicial",
        options: {
          active: "Activo",
          inactive: "Inactivo",
        },
      },
      birthDate: "Fecha de Nacimiento",
      hireDate: "Fecha de Contratación",
      timezone: "Zona horaria",
      create: "Crear Usuario",
      edit: "Editar Usuario",
    },
    tables: {
      label: "Tabla de Usuarios",
      profilePic: "Foto de perfil",
      id: "id",
      name: "Nombre",
      surname: "Apellido",
      dni: "Dni",
      email: "Correo",
      hireDate: "Fecha de Contratación",
      birthDate: "Fecha de Nacimiento",
      status: "Estado",
      actions: "Acciones",
      actionList: {
        enable: "Activar",
        disable: "Desactivar",
        edit: "Editar",
        delete: "Eliminar",
      },
    },
    success: {
      created: "Usuario creado",
    },
    errors: {
      accessExpired: "Tu sesión ha expirado, vuelve a iniciar sesión.",
      unknownError:
        "Ha ocurrido un error inesperado al obtener el usuario, contacta a soporte",
    },
    questionDelete:
      "¿Estás seguro de eliminar el usuario <strong>{name}</strong>?",
    questionEnable:
      "¿Estás seguro de habilitar al usuario <strong>{name}</strong>?",
    questionDisable:
      "¿Estás seguro de deshabilitar al usuario <strong>{name}</strong>?",
  },
  home: {
    navbar: {
      elements: {
        home: "Inicio",
        talentManager: "Gestión de talento",
        onAndOffBoarding: "Onboarding/Offboarding",
        performance: "Rendimiento",
        learningAndDevelopment: "Aprendizaje y desarrollo",
        compensations: "Compensaciones",
      },
      exit: "Salir",
      darkMode: "Modo noche",
    },
    dashboard: {
      welcome: "Bienvenido {userName}!",
      employees: "Empleados",
      welcomeMessage:
        "Mide la velocidad a la que estás creciendo segun tus ingresos mensuales",
      totalRevenue: "Ingresos totales",
      totalExpenses: "Gastos totales",
      viewAll: "Ver todo",
      tables: {
        newTalent: {
          tableLabel: "Nuevos talentos",
          name: "Nombre",
          id: "ID",
          department: "Departmento",
          position: "Puesto",
          requestDate: "Fecha solicitud",
          amount: "Monto",
          status: "Estado",
          actions: "Acciones",
          actionList: {
            enable: "Activar",
            disable: "Desactivar",
            setPending: "Asignar pendiente",
          },
        },
      },
    },
    talentManager: {
      requests: "Solicitudes",
      addNew: "Agregar nuevo",
      tables: {
        talentManagerListUsers: {
          tableLabel: "Lista de usuarios",
          name: "Nombre",
          position: "Cargo",
          accessDate: "Fecha ingreso",
          actions: "Acciones",
          status: "Estado",
          actionList: {
            delete: "Eliminar",
            edit: "Editar",
          },
        },
      },
    },
  },
  catalogs: {
    USERS_STATUS_CLASS: "Estado de los usuarios",
    EMPLOYEES_STATUS_CLASS: "Estado de los empleados",
  },
  customFields: {
    title: "Campos personalizados",
    name: "Nombre del campo",
    required: "Requerido",
    type: "Tipo de campo",
    types: {
      text: "Texto",
      number: "Número",
      select: "Selección única",
      multipleSelect: "Selección múltiple",
      date: "Fecha",
    },
    default: "Valor por defecto",
    regularExpression: "Expresión Regular",
    unitOfMeasurement: "Unidad de Medida",
    min: "Valor mínimo",
    max: "Valor máximo",
    negativeInfinity: "-Infinito",
    positiveInfinity: "+Infinito",
    selectionLimit: "Cantidad de selecciones simultáneas",
    values: "Ingrese valores",
    addField: "Agregar Campo",
    save: "Guardar",
    translates: "Traducciones del valor",
    settings: "Configuraciones",
    optionsValues: "Valores de opción",
    errors: {
      fieldAlreadyExists: "Campo personalizado ya exitente.",
    },
    sucess: {
      customFieldCreated: "Campo personalizado creado",
      customFieldUpdated: "Campo personalizado actualizado",
      customFieldDeleted: "Campo personalizado borrado",
    },
    yupRules: {
      isEmailYup: "email",
      minCharsYup: "carácteres mínimos",
      minSpecialCharsYup: "carácteres especiales mínimos",
      minNumbersYup: "números mínimos",
      minUpperCaseYup: "mayúsculas mínimas",
      minLowerCaseYup: "minúsculas mínimas",
      positiveYup: "positivo",
      integerYup: "entero",
      isGreaterYup: "mayor o igual que",
      isLessYup: "menor o igual que",
      isPositiveNumberYup: "positivo",
    },
    quantity: `Cantidad de {name}`,
    addOption: "Ingrese opción {optionIndex}",
    updateField: "Actualizar campo",
    questionDelete:
      "¿Estás seguro de eliminar el campo personalizado llamado <strong>{name}</strong>?",
    isCurrency: "Es una divisa",
    validations: "Validaciones",
  },
};
