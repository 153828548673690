import { createStore } from "vuex";
import { authStore } from "./auth/auth";
import { modulesStore } from "./modules/modules";
import { catalogsStore } from "./catalogs/catalogs";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { auth: authStore, modules: modulesStore, catalogs: catalogsStore },
});
