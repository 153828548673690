import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }

import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useUsersServices } from "@/composables/useUsersServices";
import { UserInterface } from "@/store/auth/interfaces/User.interface";
import {
  CreateOrUpdateUserInterface,
  CustomFieldValues,
} from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import {
  nextTick,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { CreateOrUpdateUserTemplate } from "./templates/CreateOrUpdateUser.template";
import GeneralForm from "@/components/globals/forms/GeneralForm.vue";
import { useCatalogsServices } from "@/composables/useCatalogsServices";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateUser',
  props: {
  initialValues: { type: Object as PropType<UserInterface>, required: false },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const { createUser, updateUser } = useUsersServices();
const { catalogs, getCatalogs } = useCatalogsServices();

const props = __props;

const initialValuesRef: Ref<UserInterface> = toRef(props, "initialValues");
const form = ref();
const formUserTemplateRef = ref(
  CreateOrUpdateUserTemplate(
    handleSubmit,
    !initialValuesRef.value,
    catalogs.value,
    customFields.value
  )
);

const emit = __emit;

watch(
  [initialValuesRef],
  async () => {
    await getCatalogs();
    formUserTemplateRef.value = CreateOrUpdateUserTemplate(
      handleSubmit,
      !initialValuesRef.value,
      catalogs.value,
      customFields.value
    );
    if (!initialValuesRef.value) {
      resetForms();
    }
  },
  { deep: true, immediate: true }
);

async function handleSubmit() {
  const { user } = form.value.values;
  if (!initialValuesRef.value) {
    const newUser = await createUser(user);
    /*
    {
      email: 'fdgfd@435.fdsa',
      registrationDate: '2024-11-05T13:49:28.080Z',
      timezone: 'America/Mexico_City',
      employee: {},
      account: { id: 1 },
      status: { id: 'f0950f06-f43f-49d8-a0d2-47ab694881aa' },
      id: 16,
      createdAt: null,
      updatedAt: '2024-11-05T13:49:28.094Z'
    }
    */
    if (newUser) {
      emit("handleSubmit", { user: newUser, isCreating: true });
    }
  } else {
    const editedUser = { ...user, ...(await updateUser(user)) };
    if (editedUser) {
      emit("handleSubmit", { user: editedUser, isCreating: false });
    }
  }
}

function formatValues(values: UserInterface): CreateOrUpdateUserInterface {
  // Limpiar los custom fields ya sea de los custom fields borrados u options de un custom fields borrados
  const customFieldsFiltered: CustomFieldValues = Object.fromEntries(
    Object.entries(values.customFields)
      .filter(([id]) => {
        return (
          customFields.value.findIndex((customField) => customField.id === id) >
          -1
        );
      })
      .map((customField) => {
        const [id, values] = customField;

        // Es una opcion del multi-select
        if (Array.isArray(values)) {
          const newValues = values.filter(({ value }) => {
            const customField = customFields.value.find(
              (customField) => customField.id === id
            );
            return (
              Object.keys(customField.metadata.options).findIndex((key) => {
                return key === value;
              }) > -1
            );
          });
          return [id, newValues];
        }
        return [id, values];
      })
  );
  const user: CreateOrUpdateUserInterface = {
    id: values.id,
    name: values.name,
    email: values.email,
    status: { id: `${values.status.id}` },
    customFields: customFieldsFiltered ?? {},
  };
  return user;
}

watch(
  initialValuesRef,
  async () => {
    await nextTick();
    resetForms();
    if (initialValuesRef.value) {
      const user = formatValues(initialValuesRef.value);
      form.value?.setValues({ user });
    }
  },
  { immediate: true, deep: true }
);

function resetForms() {
  form.value.resetForm();
  formUserTemplateRef.value.sections.forEach((section) => {
    section.fields.forEach((field) => {
      if (field.type === "select") {
        form.value?.setFieldValue(field.path, "");
      }
    });
  });
}

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(GeneralForm, {
          ref_key: "form",
          ref: form,
          form: formUserTemplateRef.value,
          "form-name": "usersForm"
        }, null, 8, ["form"])
      ])
    ])
  ]))
}
}

})