import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-10" }
const _hoisted_4 = {
  class: "container",
  id: "formBox"
}
const _hoisted_5 = { class: "row justify-content-center mb-3" }
const _hoisted_6 = { class: "col-auto d-flex align-items-center" }
const _hoisted_7 = { class: "textBlack" }
const _hoisted_8 = { class: "row justify-content-between mb-4" }
const _hoisted_9 = { class: "col-auto" }
const _hoisted_10 = { class: "col-auto" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col p-0" }

import { useAuthServices } from "@/composables/useAuthServices";
import { useValidations } from "@/composables/useValidations";
import { ref } from "vue";
import { validateEmailStep1 } from "./forms/templates/ValidateEmailStep1.template";
import { validateEmailStep2 } from "./forms/templates/ValidateEmailStep2.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ValidateEmail',
  emits: ["goToLogin"],
  setup(__props, { emit: __emit }) {

const { validateEmail, resendValidateEmail } = useAuthServices();
const validations = useValidations();

const emit = __emit;
const isFirstStep = ref<boolean>(true);
const formRef = ref();
const formValidateEmailStep1 = ref(
  validateEmailStep1(submitValidateEmail, validations)
);
const formValidateEmailStep2 = ref(
  validateEmailStep2(submitValidateEmail, validations)
);

function goToLogin(): void {
  isFirstStep.value = true;
  formRef.value.resetForm({});
  emit("goToLogin");
}

async function submitValidateEmail(): Promise<void> {
  const { valid } = formRef.value.meta();
  const values = formRef.value.values();
  if (valid) {
    if (isFirstStep.value) {
      await validateEmail(values, goToLogin);
    } else {
      isFirstStep.value = await resendValidateEmail(values);
    }
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t("login.validateEmailTitle")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[3] || (_cache[3] = _createElementVNode("i", { class: "bi bi-arrow-left-circle actionableLabel mr-1" }, null, -1)),
              _createElementVNode("span", {
                class: "actionableLabel",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (goToLogin()))
              }, _toDisplayString(_ctx.$t("login.name")), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              (isFirstStep.value)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "actionableLabel",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (isFirstStep.value = false))
                  }, _toDisplayString(_ctx.$t("login.resendValidateEmail")), 1))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: "actionableLabel",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (isFirstStep.value = true))
                  }, _toDisplayString(_ctx.$t("login.haveACodeYet")), 1))
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              (isFirstStep.value)
                ? (_openBlock(), _createBlock(GeneralForm, {
                    key: 0,
                    ref_key: "formRef",
                    ref: formRef,
                    form: formValidateEmailStep1.value,
                    formName: "validateEmail"
                  }, null, 8, ["form"]))
                : (_openBlock(), _createBlock(GeneralForm, {
                    key: 1,
                    ref_key: "formRef",
                    ref: formRef,
                    form: formValidateEmailStep2.value,
                    formName: "validateEmail"
                  }, null, 8, ["form"]))
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})