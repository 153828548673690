import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  id: "authContainer",
  class: "container-fluid m-0 w-100"
}
const _hoisted_2 = {
  class: "row justify-content-between h-100 w-100vw",
  id: "loginContainer"
}
const _hoisted_3 = {
  class: "col-lg-5 col-7 d-flex p-0",
  id: "loginBoxContainer"
}
const _hoisted_4 = { class: "container-fluid d-grid" }
const _hoisted_5 = { class: "row justify-content-end" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = {
  id: "carouselAuthViews",
  class: "carousel slide"
}
const _hoisted_10 = { class: "carousel-inner text-light text-center" }
const _hoisted_11 = { class: "carousel-item active" }
const _hoisted_12 = { class: "carousel-item" }
const _hoisted_13 = { class: "carousel-item" }
const _hoisted_14 = {
  class: "col-lg-7 col-5 d-flex p-0 justify-content-center align-content-center",
  id: "carouselImages"
}
const _hoisted_15 = {
  id: "carouselImagesLogin",
  class: "carousel slide w-100"
}
const _hoisted_16 = { class: "carousel-inner h-100" }

import ForgotPassword from "@/components/auth/ForgotPassword.vue";
import LoginBox from "@/components/auth/LoginBox.vue";
import ValidateEmail from "@/components/auth/ValidateEmail.vue";
import ChangeLang from "@/components/lang/ChangeLang.vue";
import { useToast } from "@/composables/useToastService";
import { Carousel } from "bootstrap";
import { onBeforeMount, onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthView',
  setup(__props) {

const store = useStore();
const { t } = useI18n();

const carouselBoxesInstance: Ref<Carousel | null> = ref(null);

const imagesCarousel = ref([
  "images/login/carousel/login-carousel-image-1.png",
  "images/login/carousel/login-carousel-image-2.jpg",
  "images/login/carousel/login-carousel-image-3.jpg",
  "images/login/carousel/login-carousel-image-4.jpg",
]);

function goToLogin() {
  carouselBoxesInstance.value?.to(0);
}

// lifecycle hooks
onBeforeMount(() => {
  if (store.state.auth.launchExpiredSessionToast) {
    useToast().infoToast(t("users.errors.accessExpired"));
    store.commit("auth/setLaunchExpiredSessionToast", false);
  }
});
onMounted(() => {
  new Carousel("#carouselImagesLogin", {
    interval: 5000,
    keyboard: false,
    ride: true,
  }).cycle();
  carouselBoxesInstance.value = new Carousel("#carouselAuthViews", {
    interval: 99999999999999,
    touch: false,
  });
  carouselBoxesInstance.value.cycle();
  carouselBoxesInstance.value?.to(3);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(ChangeLang)
            ])
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("div", {
            class: "row",
            id: "mainLogoContainer"
          }, [
            _createElementVNode("div", { class: "col-auto" }, [
              _createElementVNode("img", {
                src: "images/logos/mainLogoShort.png",
                alt: ""
              })
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(LoginBox)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(ForgotPassword, { onGoToLogin: goToLogin })
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(ValidateEmail, { onGoToLogin: goToLogin })
                  ])
                ])
              ])
            ])
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", {
            class: "row mb-4 align-self-end justify-content-end",
            id: "longLogoContainer"
          }, [
            _createElementVNode("div", { class: "col-auto" }, [
              _createElementVNode("img", {
                src: "images/logos/mainLogoLong.png",
                alt: ""
              })
            ])
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(imagesCarousel.value, (image, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: image,
                class: _normalizeClass(`carousel-item w-100 h-100 ${
                index === 0 ? 'active' : ''
              }`),
                style: _normalizeStyle({
                backgroundImage: `url('${image}')`,
              })
              }, null, 6))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}
}

})