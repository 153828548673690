import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid headerView pb-3 pt-3" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-auto" }
const _hoisted_4 = { class: "col-auto" }

import { ref } from "vue";
import RoundedIcon from "../icons/RoundedIcon.vue";
import BasicInput from "../inputs/BasicInput.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderViews',
  props: {
  searchBar: {
    type: Boolean,
    required: false,
  },
},
  setup(__props) {

const props = __props;

const searchText = ref("");

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BasicInput, {
          name: "searchHeader",
          placeholder: _ctx.$t('global.search'),
          modelValue: searchText.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event))
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(RoundedIcon, {
          fontSize: "1.2em",
          width: "2.1em",
          icon: "bi-bell",
          bordered: true
        })
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col" }, null, -1)),
      _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "col-auto d-flex justify-content-end",
        id: "headerLogo"
      }, [
        _createElementVNode("img", {
          src: "images/logos/mainLogoLong.png",
          alt: "mainLogoLong"
        })
      ], -1))
    ])
  ]))
}
}

})