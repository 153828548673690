<template>
  <div
    :style="{
      fontSize: props.fontSize,
      width: props.width,
      height: props.width,
    }"
    :class="`iconContainer bgWhite borderRadiusCircle d-flex justify-content-center align-items-center ${
      props.bordered ? 'border' : ''
    }`"
  >
    <i :class="`bi ${props.icon}`"></i>
  </div>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";
const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  fontSize: {
    type: String,
    required: false,
  },
  width: {
    type: String,
    required: false,
  },
  bordered: {
    type: Boolean,
    required: false,
  },
});
</script>
<style lang="scss" scoped>
.iconContainer {
  width: 2.5rem;
  height: 2.5rem;
}
</style>
