import i18n from "@/shared/locales/i18n";

export interface StatusInterface {
  bgColor?: string;
  color?: string;
  value: string;
}

export function getCatalogValue(header, catalogId): StatusInterface {
  const catalog = header.metadata.catalog.catalogs.find(
    (catalog) => catalog.id === catalogId
  );
  const lang = i18n.global.locale;
  const values: StatusInterface = Object.keys(catalog.catalogMetadata).reduce(
    (acc, key) => {
      if (key === "language") {
        acc.value = catalog.catalogMetadata.language
          ? catalog.catalogMetadata.language[lang.value]
          : catalog.name;
      } else {
        acc[key] = catalog.catalogMetadata[key];
      }
      return acc;
    },
    { value: "-" }
  );
  return values;
}
