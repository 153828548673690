import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-100 w-100 d-flex justify-content-center align-items-center" }

import { StatusInterface } from "@/shared/globals/helpers/Catalogs.helper";
import { PropType } from "vue";

export interface Status {
  value: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'StatusBadge',
  props: {
  status: {
    type: Object as PropType<StatusInterface>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "badge rounded-pill",
      style: _normalizeStyle({
        backgroundColor: __props.status?.bgColor || '#cccccc',
        color: __props.status?.color || '#000000',
      })
    }, _toDisplayString(__props.status.value || "Estado desconocido"), 5)
  ]))
}
}

})