import axiosInstance from "@/middlewares/Routing.middleware";
import { Catalog } from "../interfaces/Catalogs.interface";
import { CatalogClassInterface } from "../interfaces/CatalogClass.interface";

export async function GetCatalogs(): Promise<CatalogClassInterface[]> {
  return (await axiosInstance.get("/catalogs")).data;
}

export async function GetCatalogClasses() {
  return await axiosInstance.get("/catalogs/classes");
}

export async function CreateCatalog(catalog: Catalog) {
  return await axiosInstance.post("/catalogs", catalog);
}

export async function GetCatalogByClassName(className: string) {
  return await axiosInstance.get("/catalogs", { params: { className } });
}
