<template>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    :id="canvasId"
    :aria-labelledby="`${canvasId}Label`"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" :id="`${canvasId}Label`">{{ canvasName }}</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        @click="closeOffcanvas"
      ></button>
    </div>
    <div class="offcanvas-body">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, onMounted, Ref, ref } from "vue";

const props = defineProps({
  canvasId: { type: String, required: true },
  canvasName: { type: String, required: true },
});

const emit = defineEmits(["closed"]);

const canvas: Ref<Element> = ref(undefined);

function closeOffcanvas() {
  emit("closed");
}

onMounted(() => {
  canvas.value = document.getElementById(props.canvasId);
  canvas.value.addEventListener("hidden.bs.offcanvas", (event) => {
    emit("closed");
  });
});
</script>

<style lang="scss" scoped>
.offcanvas {
  width: 30em !important;
}
</style>
