<template>
  <div class="input-group">
    <span class="input-group-append d-flex align-items-center w-0">
      <button class="btn rounded-pill bg-white buttonSearch" type="button">
        <i class="bi bi-search"></i>
      </button>
    </span>
    <input
      class="form-control rounded-pill"
      type="text"
      :placeholder="props.placeholder"
      v-model="inputValue"
      :id="`${props.name}Input`"
    />
  </div>
</template>

<script lang="ts" setup>
import { defineModel, defineProps } from "vue";
const props = defineProps({
  name: { type: String, required: true },
  placeholder: { type: String, required: false },
});

const inputValue = defineModel();
</script>

<style lang="scss" scoped>
input {
  padding-left: 2.5em;
}

.buttonSearch {
  left: 1em;
  padding: 0;
  background-color: transparent;
  border: none;
  z-index: 10 !important;
}
</style>
